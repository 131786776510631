import { Button } from "components/Elements";
import { Form, InputField, PhoneInputField } from "components/Form";
import * as z from "zod";
import { useJoinWaitingList } from "../api/joinWaitingList";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const schema = z.object({
  phone: z.string().min(1, "Required"),
  name: z.string().min(1, "Required"),
  email: z.string().min(1, "Required"),
});

export const WaitingListForm = ({ onSuccess }) => {
  const joinWaitingListMutation = useJoinWaitingList();

  const { t } = useTranslation();
  return (
    <div>
      <Form
        onSubmit={async (values) => {
          await joinWaitingListMutation.mutateAsync({
            data: values,
          });
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              label={t("auth.name")}
              error={formState.errors["name"]}
              registration={register("name")}
            />
            <InputField
              label={t("auth.email_address")}
              type="email"
              error={formState.errors["email"]}
              registration={register("email")}
            />
            <PhoneInputField
              label={t("auth.phone")}
              error={formState.errors["phone"]}
              registration={register("phone")}
            />
            <div className="mt-2">
              <Button
                isLoading={joinWaitingListMutation.isLoading}
                type="submit"
                className="w-full"
              >
                {t("interface.submit")}
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

WaitingListForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
