import { Button } from "components/Elements";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useUpdateTransactionStatus } from "../api/updateTransactionStatus";

export const BidPaymentSuccess = () => {
  const { search } = useLocation();

  const params = new URLSearchParams(search);

  const status = params.get('status')
  const tx_ref = params.get('tx_ref')
  const transaction_id = params.get('transaction_id')

  const updateTransactionStatusMutation = useUpdateTransactionStatus();

  useEffect(async () => {
    await updateTransactionStatusMutation.mutateAsync({
      tx_ref,
      status,
      transaction_id
    });
  }, []);

  return (
    <div>
      <div className="flex flex-col justify-start items-center space-x-2">
        <div className="">status: {status}</div>
        <div className="">tx_ref: {tx_ref}</div>
        <div className="">transaction_id: {transaction_id}</div>
      </div>
    </div>
  );
};
