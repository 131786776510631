import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDjtwahZnSBgTQ-Q34P0H7L1Ce42qvYCkg",
  authDomain: "dejunk-f454c.firebaseapp.com",
  projectId: "dejunk-f454c",
  storageBucket: "dejunk-f454c.appspot.com",
  messagingSenderId: "188651732076",
  appId: "1:188651732076:web:294ff3a7ab7af7a6a46c49",
  measurementId: "G-RVN48XFG1Y"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
