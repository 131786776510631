import { MainLayout } from "components/Layouts";
import React from "react";
import { Hero } from "../components/Hero";
import { Team } from "../components/Team";
import { Testimonial } from "../components/Testimonial";
import ClutterBro from "assets/images/Clutter-bro.svg";
import { Features } from "../components/Features";
import { useTranslation } from "react-i18next";

export const About = () => {
  // scroll to top
  window.scrollTo(0, 0);
  const { t } = useTranslation();


  return (
    <MainLayout>
      <div className="flex flex-col md:flex-row justify-between items-center my-8 md:mx-12">
        <div className="md:mb-12">
          <div className="pacifico text-start flex flex-col">
            <div className="text-6xl md:text-7xl capitalize font-black rounded-lg text-gray-800 p-4">
              <span className="text-red-500 ">Swap</span> or sell
            </div>
            <div className="text-xl md:text-2xl font-black text-gray-800 p-4">
              Clear your space and make some cash!
            </div>
          </div>
        </div>
        <div class="mt-8 md:mt-0 lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            class="object-cover object-center rounded"
            alt="hero"
            src={ClutterBro}
          />
        </div>
      </div>
      <div className="flex flex-col mt-12 mb-12 md:mx-12">
        <div className="text-start text-2xl mb-4 font-light">
          {t("landing.dejunk_platform")}
        </div>
      </div>

      <Features />
      <Hero />
      <Team />
      <Testimonial />
    </MainLayout>
  );
};
