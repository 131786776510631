import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getbid = ({ id }) => {
  return axios.get(`/bids/${id}`);
};

export const useBid = ({ id, config }) => {
  return useQuery({
    ...config,
    queryKey: ['bid', id],
    queryFn: () => getbid({ id }),
  });
};
