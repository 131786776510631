import { MainLayout } from "components/Layouts";
import { ForgotPasswordForm } from "../components/ForgotPasswordForm";
import { Layout } from "../components/Layout";
import { useTranslation } from "react-i18next";

export const ForgotPassword = () => {
  const { t } = useTranslation();
  return (
    <MainLayout title={t('auth.forgot_password')}>
      <Layout title={t('auth.forgot_password')}>
      <div className="text-gray-700">
          <p className="mb-4 text-sm">
            {t('auth.forgot_password_description')}
          </p>
        </div>
        <ForgotPasswordForm />
      </Layout>
    </MainLayout>
  );
};
