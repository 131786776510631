import { Button } from "components/Elements";
import { Form, InputField } from "components/Form";

import { useAuth } from "lib/auth";
import PropTypes from "prop-types";
import { Link, useLocation, useParams } from "react-router-dom";
import * as z from "zod";
import { loginWithGoogle } from "../api/loginWithGoogle";
import { useEffect, useState } from "react";
import storage from "utils/storage";
import { useTranslation } from "react-i18next";

const schema = z.object({
  email: z.string().min(1, "Required"),
  password: z.string().min(1, "Required"),
});

export const LoginForm = ({ onSuccess }) => {
  const { login, isLoggingIn } = useAuth();

  const [loginWithGoogleLoading, setLoginWithGoogleLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralCode = queryParams.get('referralCode');
  const { t } = useTranslation();

  useEffect(() => {
    if (referralCode) storage.setReferralCode(referralCode);
  }, []);

  const handleGoogleLogin = async () => {
    setLoginWithGoogleLoading(true);
    await loginWithGoogle();
    onSuccess();
    setLoginWithGoogleLoading(false);
  };

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          await login(values);
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label={t('auth.email_address')}
              error={formState.errors["email"]}
              registration={register("email")}
            />
            <InputField
              type="password"
              label={t('auth.password')}
              error={formState.errors["password"]}
              registration={register("password")}
            />
            <div>
              <Button isLoading={isLoggingIn} type="submit" className="w-full">
                {t('auth.login')}
              </Button>
            </div>
          </>
        )}
      </Form>

      <div className="mt-4">
        <Button
          onClick={handleGoogleLogin}
          variant="outline"
          className="w-full"
          isLoading={loginWithGoogleLoading}
          startIcon={
            <svg
              class="mr-2 -ml-1 w-4 h-4"
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="google"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 488 512"
            >
              <path
                fill="currentColor"
                d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
              ></path>
            </svg>
          }
        >
          {t('auth.continue_with_google')}
        </Button>
      </div>
      <div className="mt-2 flex items-center justify-end">
        <div className="text-sm">
          <Link
            to="/auth/forgot-password"
            className="font-medium text-blue-600 hover:text-blue-500"
          >
            {t('auth.forgot_password')}?
          </Link>
        </div>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
