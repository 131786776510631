import React from "react";
import { CheckCircle } from "react-feather";
import { useTranslation } from "react-i18next";

export const Features = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-gradient-to-r from-zinc-800 to-red-600 rounded-3xl p-10">
      <div className="container mx-auto flex flex-col items-center">
        <h2 className="text-4xl font-semibold text-white mb-8 pacifico">
          {t('landing.features')}
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Feature 1 */}
          <div className="flex items-center">
            <div className=" p-4 rounded-full mr-4">
              <CheckCircle className="w-8 h-8 text-white" />
            </div>
            <div>
              <h3 className="text-xl font-semibold text-white">Dejunk</h3>
              <p className="text-gray-300 text-xs">
                {t('landing.post_items')}
              </p>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="flex items-center">
            <div className=" p-4 rounded-full mr-4">
              <CheckCircle className="w-8 h-8 text-white" />
            </div>
            <div>
              <h3 className="text-xl font-semibold text-white">{t('landing.swap')}</h3>
              <p className="text-gray-300 text-xs">
                {t('landing.swap_with')}
              </p>
            </div>
          </div>

          {/* Feature 3 */}
          <div className="flex items-center">
            <div className=" p-4 rounded-full mr-4">
              <CheckCircle className="w-8 h-8 text-white" />
            </div>
            <div>
              <h3 className="text-xl font-semibold text-white">{t('landing.purchase_request')} (PRs)</h3>
              <p className="text-gray-300 text-xs">
                {t('landing.purchase_request_description')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
