import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Header, Spinner } from "components/Elements";
import { useMyPosts } from "../api/getMyPosts";
import { CreateForBarter } from "features/dejunk";
import { useAuth } from "lib/auth";

export const Listings = () => {
  const postsQuery = useMyPosts();

  const navigate = useNavigate();
  const { user } = useAuth();

  if (postsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!postsQuery.data) return null;

  return (
    <div>
      <Header label="My Listings" />
      {/* {user.phone && <CreateForBarter />} */}
      {postsQuery.data?.length === 0 && (
        <>
          <div className="flex items-center justify-center font-bold text-gray-500 text-xl text-center p-10 my-4">
            <div>You have no items in your listings.</div>
          </div>
        </>
      )}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-8">
        {postsQuery.data &&
          postsQuery.data?.map((post, index) => (
            <div key={post._id}>
              <div className="">
                {post?.images && post?.images[0] && (
                  <Card
                    // open={open}
                    id={post._id}
                    image={post?.images && post?.images[0]}
                    title={post.title}
                    contact={post.contact}
                    pick_up_location={post.pick_up_location}
                    userId={post.user}
                    for_barter={post.for_barter}
                    for_sale={post.for_sale}
                    for_donation={post.for_donation}
                    original_price={post.original_price}
                    selling_price={post.selling_price}
                    paid_for={post.paid_for}
                    slug={post.slug}
                  />
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
