import { Navigate, Route, Routes } from 'react-router-dom';

import { Listing } from './Listing';
import { Listings } from './Listings';
import { MakePayment } from './MakePayment';
import { PayForPost } from './PayForPost';
import { PaymentSuccessful } from './PaymentSuccessful';

export const ListingsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Listings />} />
      <Route path=":slug" element={<Listing />} />
      <Route path="make-payment" element={<MakePayment />} />
      <Route path=":slug/payment-successful" element={<PaymentSuccessful />} />
      <Route path=":slug/pay-for-post" element={<PayForPost />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
