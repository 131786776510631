import { Navigate, Route, Routes } from 'react-router-dom';
import { Notifications } from './Notifications';

export const NotificationsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Notifications />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
