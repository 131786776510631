import { Button } from "components/Elements";
import { Form, PhoneInputField } from "components/Form";
import PropTypes from "prop-types";
// import { Link } from 'react-router-dom';
import * as z from "zod";
import { useRequestOtp } from "../api/requestOtp";
import { useCookies } from "react-cookie";

const schema = z.object({
  phone: z.string().min(1, "Required"),
});

export const GetOTPForm = ({ onSuccess }) => {
  const requestOTPMutation = useRequestOtp();
  const [cookies, setCookie] = useCookies(["phoneNumber"]);

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          const { phone } = values;
          var prefix = "+256";
          const phoneNumber = prefix + phone;
          await requestOTPMutation.mutateAsync({
            data: {
              phone: phoneNumber,
            },
          });
          setCookie("phoneNumber", phoneNumber, { path: "/" });
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <PhoneInputField
              error={formState.errors["phone"]}
              registration={register("phone")}
            />

            <div>
              <Button
                isLoading={requestOTPMutation.isLoading}
                type="submit"
                className="w-full"
              >
                Continue
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

GetOTPForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
