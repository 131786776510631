import { Button } from "components/Elements";
import { Form, InputField } from "components/Form";
import { User } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import * as z from "zod";

import { useForgotPassword } from "../api/forgotPassword";
import { useTranslation } from "react-i18next";

const schema = z.object({
  email: z.string().min(1, "Required").email({ message: "Enter valid email" }),
});

export const ForgotPasswordForm = () => {
  const forgotPasswordMutation = useForgotPassword();
const { t } = useTranslation()
  const navigate = useNavigate();

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          await forgotPasswordMutation.mutateAsync({ email: values });
          navigate("/auth/forgot-password/check-email");
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              icon={<User />}
              placeholder={t('auth.email_address')}
              error={formState.errors["email"]}
              registration={register("email")}
            />

            <div>
              <Button
                isLoading={forgotPasswordMutation.isLoading}
                type="submit"
                className="w-full text-gray-g9"
              >
                {t('auth.forgot_password')}
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          <Link
            to="/auth/login"
            className="font-medium text-orange-o10 hover:text-green-500"
          >
            {t('auth.go_to_login')}
          </Link>
        </div>
      </div>
    </div>
  );
};
