import React from "react";
import { Mail, Twitter } from "react-feather";

export const TeamMate = ({ src, name, title, description, twitter, mail }) => {
  return (
    <div class="p-4 lg:w-1/2">
      <div class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
        <img
          alt="team"
          class="flex-shrink-0 hover:grayscale rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
          src={src}
        />
        <div class="flex-grow sm:pl-8">
          <h2 class="title-font font-medium text-lg text-gray-900">{name}</h2>
          <h3 class="text-gray-500 mb-3">{title}</h3>
          <p class="mb-4">{description}</p>
          <span class="inline-flex">
            <a href={`https://x.com/${twitter}`} class="ml-2 text-gray-500">
              <Twitter className="w-5 h-5" />
            </a>
            <a href={`mailto:${mail}`} class="ml-2 text-gray-500">
              <Mail className="w-5 h-5 " />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};
