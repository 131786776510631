import { MainLayout } from "components/Layouts";
import { useNavigate } from "react-router-dom";

import { Layout } from "../components/Layout";
import { LoginForm } from "../components/LoginForm";
import { useTranslation } from "react-i18next";


export const Login = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <MainLayout title={t('auth.login_to_account')}>
      <Layout title={t('auth.login_to_account')}>
        <LoginForm onSuccess={() => navigate("/app")} />
      </Layout>
    </MainLayout>
  );
};
