import { Button } from "components/Elements";
import { Form, InputField, PhoneInputField } from "components/Form";
import { useAuth } from "lib/auth";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCompleteRegistration } from "../api/completeRegistration";

export const CompleteRegistration = () => {
  const completeRegistrationMutation = useCompleteRegistration();
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.displayName) return navigate("/app");
  }, [user.displayName, navigate]);

  return (
    <div className="md:mx-24 py-24">
      <div className="font-bold text-2xl text-center">
        Complete your registration
      </div>
      <div className="text-md text-gray-600 text-center mx-auto w-80">
        Just one more step to complete the registration and you can start
        posting your items right away!
      </div>
      <div className="mx-auto md:w-[50%] my-4">
        <Form
          id="complete-registration"
          onSubmit={async (values) => {
            const { phone, email, displayName } = values;
            var prefix = "+256";
            const phoneNumber = prefix + phone;
            await completeRegistrationMutation.mutateAsync({
              userId: user._id || user.uid,
              data: {
                phone: phoneNumber,
                email,
                displayName,
              },
            });
            return navigate("/app");
          }}
        >
          {({ register, formState }) => (
            <>
              <InputField
                label="Name"
                error={formState.errors["displayName"]}
                registration={register("displayName")}
              />
              {!user?.phone && (
                <PhoneInputField
                  label="Phone"
                  error={formState.errors["phone"]}
                  registration={register("phone")}
                />
              )}
              {!user?.email && (
                <InputField
                  label="Email"
                  type="email"
                  error={formState.errors["email"]}
                  registration={register("email")}
                />
              )}
            </>
          )}
        </Form>
        <Button
          form="complete-registration"
          type="submit"
          size="sm"
          className="my-4 mx-auto"
          isLoading={completeRegistrationMutation.isLoading}
        >
          Complete registration
        </Button>
      </div>
    </div>
  );
};
