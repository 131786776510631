import PropTypes from "prop-types";
import * as React from "react";
import { Head } from "../Head";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";

export const MainLayout = ({ page, children, title }) => {
  return (
    <>
      <Head title={title} />
      <div className="min-h-screen bg-gradient-to-r from-gray-100 to-red-100">
        <div className="mx-4 md:mx-24">
          <Navbar page={page} />
          <main
            className="my-4"
          
          >
            {children}
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
