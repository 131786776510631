import { useNotifications } from "features/notifications";
import React from "react";
import { useTranslation } from "react-i18next";
import { TbBell } from "react-icons/tb";
import { Link } from "react-router-dom";

export const NotificationsLink = () => {
  const notificationsQuery = useNotifications();

  const { t } = useTranslation()

  if (notificationsQuery.isLoading) {
    return <></>;
  }

  if (!notificationsQuery.data) return null;
  return (
    <Link
      to="/app/notifications"
      className="hover:shadow-xl py-2 px-2 text-md flex items-center rounded-full gap-4"
    >
      <div className="relative">
        <TbBell className="h-6 w-6 md:h-8 md:w-8" />
        {notificationsQuery.data?.filter((not) => !not.isRead).length > 0 && (
          <span className="absolute top-0 h-4 w-4 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"></span>
        )}
      </div>
      <h1 className="hidden md:block cursor-pointer">{t('interface.notifications')}</h1>
    </Link>
  );
};
