import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotificationStore } from "stores/notifications";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

export const acceptBid = ({ id }) => {
  return axios.post(`/bids/${id}/accept`);
};

acceptBid.propTypes = {
  id: PropTypes.string.isRequired,
};

export const useAcceptBid = ({ config } = {}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (acceptedBid) => {
      await queryClient.cancelQueries("buyerbids");

      const previousBids = queryClient.getQueryData("buyerbids");

      queryClient.setQueryData(
        "buyerbids",
        previousBids?.filter((bid) => bid.id !== acceptedBid.id)
      );

      return { previousBids };
    },
    onError: (_, __, context) => {
      if (context?.previousBids) {
        queryClient.setQueryData("buyerbids", context.previousBids);
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("buyerbids");
      navigate(`/app/booked/${data.post}`);
      addNotification({
        type: "success",
        title: "Bid has been accepted",
      });
    },
    ...config,
    mutationFn: acceptBid,
  });
};
