import { BID_STATUS } from "config";
import { useBids } from "features/bids";
import React from "react";
import { useTranslation } from "react-i18next";
import { BsEggFried } from "react-icons/bs";
import { Link } from "react-router-dom";

export const BidsLink = () => {
  const bidsQuery = useBids();

  const { t } = useTranslation()

  if (bidsQuery.isLoading) {
    return <></>;
  }

  if (!bidsQuery.data) return null;

  return (
    <Link
      to="/app/bids"
      className="hover:shadow-xl py-2 px-2 text-md flex items-center rounded-full gap-4"
    >
      <div className="relative">
        <BsEggFried className="h-6 w-6 md:h-8 md:w-8" />
        {bidsQuery.data?.filter((bid) => bid.status === BID_STATUS.SUBMITTED)
          .length > 0 && (
          <span className="absolute top-0 h-4 w-4 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"></span>
        )}
      </div>
      <h1 className="hidden md:block cursor-pointer">PRs</h1>
    </Link>
  );
};
