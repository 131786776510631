import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotificationStore } from "stores/notifications";
import { useMutation } from "react-query";

export const adjustSellingPrice = ({ data, postId }) => {
  return axios.patch(`/posts/${postId}/adjust-selling-price`, data);
};

export const useAdjustSellingPrice = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (adjustingPrice) => {
      await queryClient.cancelQueries(["post", adjustingPrice?.postId]);

      const previousPost =
        queryClient.getQueryData[("post", adjustingPrice?.postId)];

      queryClient.setQueryData(["post", adjustingPrice?.postId], {
        ...previousPost,
        ...adjustingPrice.data,
        id: adjustingPrice.postId,
      });

      return { previousPost };
    },
    onError: (_, __, context) => {
      if (context?.previousPost) {
        queryClient.setQueryData(
          ["post", context.previousPost.id],
          context.previousPost
        );
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries("post", data.id);
      addNotification({
        type: "success",
        title: data.msg,
      });
    },
    ...config,
    mutationFn: adjustSellingPrice,
  });
};
