import clsx from 'clsx';

import { FieldWrapper } from './FieldWrapper';

export const InputField = (props) => {
  const { type = 'text', label, className, required, placeholder, value, multiple, onChange, maxLength, caption, registration, error, description } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <div className="my-1 text-xs flex justify-start">{description}</div>
      <input
        type={type}
        maxLength={maxLength}
        multiple={multiple}
        value={value}
        required={required}
        placeholder={placeholder}
        className={clsx(
          'appearance-none block w-full px-8 py-3 border border-gray-200 bg-white rounded-lg placeholder-gray-400 focus:bg-white focus:outline-none focus:ring-0 sm:text-sm',
          className
        )}
        onChange={onChange}
        {...registration}
      />
      <div className="my-1 text-xs flex justify-end">{caption}</div>
    </FieldWrapper>
  );
};
