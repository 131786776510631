import { Spinner } from "components/Elements";
import {
  getUser,
  verifyOtpAndLogin,
  loginWithEmailAndPassword,
  getStoredUser,
} from "features/auth";
import storage from "utils/storage";
import { initReactQueryAuth } from "react-query-auth";

async function handleUserResponse(data) {
  const { token, user } = data;
  storage.setToken(token);
  return user;
}

async function loadUser() {
  let data = {};
  if (storage.getToken()) {
    if (storage.getSignInMethod() === "google.com") {
      data = await getStoredUser();
    } else {
      data = await getUser();
    }

    return data;
  }
  return null;
}

async function loginFn(data) {
  var response;
  if (!data.phone) {
    response = await loginWithEmailAndPassword(data);
  } else {
    response = await verifyOtpAndLogin(data);
  }
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  storage.clearToken();
  window.location.assign(window.location.origin);
}

const authConfig = {
  loadUser,
  loginFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="xl" />
      </div>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);
