import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getPosts = () => {
  return axios.get('/posts');
};

export const usePosts = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['listings'],
    queryFn: () => getPosts(),
  });
};
