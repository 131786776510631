import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotificationStore } from "stores/notifications";
import PropTypes from "prop-types";
import { useMutation } from "react-query";

export const createCategory = ({ data }) => {
  return axios.post(`/posts/new-category`, data);
};

createCategory.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export const useCreateCategory = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newCat) => {
      await queryClient.cancelQueries("categories");
      const previousCategories = queryClient.getQueryData("categories");

      queryClient.setQueryData("categories", [
        ...(previousCategories || []),
        newCat.data,
      ]);

      return { previousCategories };
    },
    onError: (_, __, context) => {
      if (context?.previousCategories) {
        queryClient.setQueryData("categories", context.previousCategories);
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("categories");
      addNotification({
        type: "success",
        title: data.msg,
      });
    },
    ...config,
    mutationFn: createCategory,
  });
};
