import React from "react";
import { useBuyerBids } from "../api/getBuyerBids";
import { DeclineBid } from "./DeclineBid";
import { AcceptBid } from "./AcceptBid";
import { usePreferences } from "features/preferences";
import { BID_STATUS } from "config";
import { Spinner } from "components/Elements";

export const DeclinedBidsList = () => {
  const declinedBidsQuery = useBuyerBids();

  const preferencesQuery = usePreferences();

  if (declinedBidsQuery.isLoading || preferencesQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  }

  if (!declinedBidsQuery.data || !preferencesQuery.data) return null;

  const declinedBids = declinedBidsQuery.data?.filter(
    (bid) => bid.status === BID_STATUS.DECLINED
  );

  return (
    <>
      {declinedBids.length ? <div className="px-5 text-md font-bold mb-3 text-gray-600 bg-white rounded-2xl shadow p-3">Declined</div> : null}
      <div className="grid grid-cols-1 gap-4">
        {declinedBids.map((bid, index) => (
          <div key={index} className="bg-white rounded-2xl shadow mb-3 p-8">
            <div className="flex flex-row items-center justify-between">
              <div className="">
                <span className="text-gray-400 text-xs">Post</span> <br />
                <span className="text-gray-800 text-sm font-semibold">
                  {bid.post.title}
                </span>
              </div>
              <div className="">
                <span className="text-gray-400 text-xs">PR ID</span> <br />
                <span className="text-red-500 text-xs font-semibold">
                  {bid._id}
                </span>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="">
                <span className="text-gray-400 text-xs">Amount</span> <br />
                <span className="text-gray-800 text-md font-bold">
                  {preferencesQuery.data.currency} {bid.amount}
                </span>
              </div>
              {/* <div className="flex flex-row items-center space-x-2">
                <AcceptBid id={bid._id} />
                <DeclineBid id={bid._id} />
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
