import { useNavigate } from "react-router-dom";

import { GetOTPForm } from "../components/GetOTPForm";
import { MainLayout } from "components/Layouts";
import { Layout } from "../components/Layout";

export const GetOTP = () => {
  const navigate = useNavigate();

  return (
    <MainLayout title="Get started today">
      <Layout title="Get started today">
        <div className="text-gray-500 mb-4">
          Yeah, we simply need your valid phone number. It won’t be shared with
          anyone unless authorised by you.
        </div>
        <GetOTPForm onSuccess={() => navigate("/auth/verify-otp")} />
      </Layout>
    </MainLayout>
  );
};
