import { MainLayout } from "components/Layouts";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Layout } from "../components/Layout";
import { VerifyOTPForm } from "../components/VerifyOTPForm";
import { useCookies } from "react-cookie";

export const VerifyOTP = () => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["name"]);

  useEffect(() => {
    if (!cookies.phoneNumber) {
      navigate("/auth/get-otp");
    }
  }, [cookies.phoneNumber, navigate]);

  return (
    <MainLayout title="Verify OTP">
      <Layout title="Verify OTP">
        <div className="text-gray-500 mb-4">
          You're required to input a One-Time-Passcode that was sent to your
          mobile number.
        </div>
        <VerifyOTPForm onSuccess={() => navigate("/app")} />
      </Layout>
    </MainLayout>
  );
};
