import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getTrades = () => {
  return axios.get('/trades/mine');
};

export const useTrades = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['trades'],
    queryFn: () => getTrades(),
  });
};
