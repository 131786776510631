import { getUser } from "features/auth";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useMutation } from "react-query";
import { useNotificationStore } from "stores/notifications";
import storage from "utils/storage";

export const addPhoneNumber = ({ data }) => {
  return axios.post("/users/add-phone-number", data);
};

export const useAddPhoneNumber = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: async (data) => {
      const updatedUserData = await getUser();
      storage.setUserData(updatedUserData);
      await queryClient.invalidateQueries("auth-user");
      addNotification({
        type: "success",
        title: data.msg,
      });
    },
    ...config,
    mutationFn: addPhoneNumber,
  });
};
