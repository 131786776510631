import { UPLOADS_URL } from "config";
import { useDisclosure } from "hooks/useDisclosure";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Button, Card, Drawer, Spinner } from "components/Elements";
import { Form, InputField, SelectField } from "components/Form";
import { useCreateTrade } from "../api/createTrade";
import { useMyPosts } from "features/listings";
import { X } from "react-feather";
import { useAuth } from "lib/auth";
import { CiWarning } from "react-icons/ci";
import { useCreateBid } from "../api/createBid";
import { usePreferences } from "features/preferences";

export const Post = ({
  image,
  images,
  title,
  description,
  contact,
  pick_up_location,
  id,
  for_barter,
  for_donation,
  slug,
  paid_for,
  for_sale,
  original_price,
  selling_price,
  userId,
  category,
  period_of_usage,
  bids,
}) => {
  const { close, open, isOpen } = useDisclosure();

  const [trade, setTrade] = useState(false);

  const [buyItem, setBuyItem] = useState(false);

  const createTradeMutation = useCreateTrade();

  const preferencesQuery = usePreferences();

  const createBidMutation = useCreateBid();

  const postsQuery = useMyPosts();

  const [bidItem, setBidItem] = useState();

  const { user } = useAuth();

  console.log(bids);

  const navigate = useNavigate();

  if (postsQuery.isLoading || preferencesQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="md" />
      </div>
    );
  }

  if (!postsQuery.data || !preferencesQuery.data) return null;

  return (
    <>
      <Card
        open={open}
        image={image}
        title={title}
        contact={contact}
        pick_up_location={pick_up_location}
        id={id}
        userId={userId}
        for_barter={for_barter}
        for_sale={for_sale}
        for_donation={for_donation}
        original_price={original_price}
        selling_price={selling_price}
        paid_for={paid_for}
        slug={slug}
      />
      <Drawer
        isOpen={isOpen}
        onClose={() => {
          close();
          setTrade(false);
          setBuyItem(false);
        }}
        title={createTradeMutation.isSuccess ? "Trade initiated!" : title}
        size="sm"
        renderFooter={() => null}
      >
        {createTradeMutation.isSuccess ? (
          <>
            <div className="mb-8">
              Awesome! You've successfully initiated a new trade. The receiver
              has been notified and will respond accordingly.
            </div>
            <div className="flex flex-row justify-start items-center space-x-2">
              <Button
                onClick={() => {
                  close();
                  setTrade(false);
                }}
                size="xs"
                variant="outline"
              >
                Continue trading
              </Button>
              <Button onClick={() => navigate("/app/trades")} size="xs">
                Go to trades
              </Button>
            </div>
          </>
        ) : (
          <>
            {createBidMutation.isSuccess ? (
              <>
                <div className="mb-8">
                  Awesome! You've successfully submitted your Purchase request
                  (PR) for the item. You'll be notified as soon as your PR is
                  either accepted or denied.
                </div>
                <div className="flex flex-row justify-center items-center space-x-2">
                  <Button
                    onClick={() => {
                      close();
                      setTrade(false);
                      setBuyItem(false);
                      setBidItem(false);
                      createBidMutation.reset();
                    }}
                    variant="outline"
                  >
                    Back Home
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="mb-8">
                  <Slide>
                    {images?.map((image, q) => (
                      <div key={q} className=" md:mx-8">
                        <img
                          src={
                            image
                              ? UPLOADS_URL + "/" + image
                              : UPLOADS_URL + "/" + "default-image.png"
                          }
                          alt="post"
                          className="w-contain rounded-2xl shadow"
                        />
                      </div>
                    ))}
                  </Slide>
                </div>
                {(!trade || !buyItem) && (
                  <>
                    <div className="mb-4">
                      <div className="text-sm font-bold text-gray-700">
                        Description
                      </div>
                      <div className="text-md bg-gray-50 p-2 rounded-md mt-1">
                        {description}
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="text-sm font-bold text-gray-700">
                        Category
                      </div>
                      <div className="text-md bg-gray-50 p-2 rounded-md mt-1">
                        {category}
                      </div>
                    </div>
                    {original_price && (
                      <div className="mb-4">
                        <div className="text-sm font-bold text-gray-700">
                          Original Price
                        </div>
                        <div className="text-md bg-gray-50 p-2 rounded-md mt-1">
                          {preferencesQuery.data.currency} {original_price}
                        </div>
                      </div>
                    )}
                    {selling_price && (
                      <div className="mb-4">
                        <div className="text-sm font-bold text-gray-700">
                          Selling Price
                        </div>
                        <div className="text-md bg-gray-50 p-2 rounded-md mt-1">
                          {preferencesQuery.data.currency} {selling_price}
                        </div>
                      </div>
                    )}
                    <div className="mb-4">
                      <div className="text-sm font-bold text-gray-700">
                        Trade/Swap Location
                      </div>
                      <div className="text-md bg-gray-50 p-2 rounded-md mt-1">
                        {pick_up_location}
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="text-sm font-bold text-gray-700">
                        Period of Usage
                      </div>
                      <div className="text-md bg-gray-50 p-2 rounded-md mt-1">
                        {period_of_usage}
                      </div>
                    </div>
                  </>
                )}
                <div className="">
                  {trade ? (
                    <div className="bg-gray-100 p-4 rounded-md">
                      <div className="flex flex-row items-center justify-between font-bold text-xl mb-3">
                        <span>Create new trade</span>
                        <button
                          onClick={() => setTrade(false)}
                          className="text-xs flex flex-row items-center space-x-2"
                        >
                          <X /> Close
                        </button>
                      </div>
                      <Form
                        id="create-post"
                        onSubmit={async (values) => {
                          values.receiverPostId = id;
                          await createTradeMutation.mutateAsync({
                            data: values,
                          });
                        }}
                      >
                        {({ register, formState }) => (
                          <>
                            <SelectField
                              label="Select from your listings"
                              registration={register("initiatorPostId")}
                              defaultValue="Select Item"
                              options={postsQuery.data}
                            />
                            <InputField
                              label={`Top up amount (${preferencesQuery.data.currency})`}
                              error={formState.errors["top_up"]}
                              registration={register("top_up")}
                              description="Add a top up if item you're trading is of less value."
                            />
                            <InputField
                              label={`Expected top up amount (${preferencesQuery.data.currency})`}
                              error={formState.errors["expected_top_up"]}
                              registration={register("expected_top_up")}
                              description="Amount you expect the receiver to add if item you're trading is of higher value"
                            />

                            <Button
                              type="submit"
                              isLoading={createTradeMutation.isLoading}
                              className="w-full my-4"
                            >
                              Continue & Trade
                            </Button>
                          </>
                        )}
                      </Form>
                    </div>
                  ) : user?._id === userId ? (
                    <></>
                  ) : postsQuery.data ? (
                    <>
                      {postsQuery.data.length <= 0 && (
                        <div className="flex flex-row items-center space-x-2 text-xs text-red-500">
                          <CiWarning />
                          <span>
                            Trade can be activated by adding items in your
                            listings
                          </span>
                        </div>
                      )}
                      <Button
                        className="w-full my-4"
                        onClick={() => setTrade(true)}
                        disabled={postsQuery.data.length <= 0 ? true : false}
                      >
                        Trade
                      </Button>
                      {bidItem ? (
                        <div className="bg-gray-100 p-4 rounded-md">
                          <div className="flex flex-row items-center justify-between font-bold text-md mb-3">
                            <span>Submit a Purchase Request</span>
                            <button
                              onClick={() => setBidItem(false)}
                              className="text-xs flex flex-row items-center space-x-2"
                            >
                              <X /> Close
                            </button>
                          </div>
                          <Form
                            id="create-post"
                            onSubmit={async (values) =>
                              await createBidMutation.mutateAsync({
                                data: {
                                  postId: id,
                                  amount: values.amount,
                                },
                              })
                            }
                          >
                            {({ register, formState }) => (
                              <>
                                <InputField
                                  label={`How much do you want to buy this item? (${preferencesQuery.data.currency})`}
                                  error={formState.errors["amount"]}
                                  registration={register("amount")}
                                />

                                <Button
                                  className="w-full my-4"
                                  variant="outline"
                                  type="submit"
                                  isLoading={createBidMutation.isLoading}
                                >
                                  Submit
                                </Button>
                              </>
                            )}
                          </Form>
                        </div>
                      ) : (
                        <Button
                          className="w-full my-4"
                          onClick={() => setBidItem(true)}
                          disabled={!user.phone ? true : false}
                        >
                          Buy
                        </Button>
                      )}
                    </>
                  ) : (
                    <div className="bg-orange-400 text-white p-2 rounded-md">
                      <div className="">Initiate trade</div>
                      <div className="text-xs">
                        Please add items to listing before initiating trade to
                        activate initiate trade
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </Drawer>
    </>
  );
};
