import { AppProvider } from 'providers/app';
import { AppRoutes } from 'routes';

// import 'sweetalert2/src/sweetalert2.scss';

function App() {
  return (
    <>
    <AppProvider>
      <AppRoutes />
    </AppProvider>
    </>
  );
}

export default App;
