import { API_URL } from "config";
import { useNotificationStore } from "stores/notifications";
import storage from "utils/storage";
import Axios from "axios";
import PropTypes from "prop-types";

function authRequestInterceptor(config) {
  const token = storage.getToken();
  const signInMethod = storage.getSignInMethod();
  if (token) {
    if (signInMethod === "google.com") {
      config.headers.authorization = `Firebase ${token}`;
    } else {
      config.headers.authorization = `Bearer ${token}`;
    }
  }
  config.headers.Accept = "application/json";
  return config;
}

authRequestInterceptor.propTypes = {
  config: PropTypes.any,
};

export const axios = Axios.create({
  baseURL: API_URL,
});
// timeout in milliseconds
axios.defaults.timeout = 50000;
axios.defaults.timeoutErrorMessage =
  "😟 Server is taking longer than usual to respond.";

// add multipart/form-data support
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

axios.interceptors.request.use(authRequestInterceptor);
// axios.interceptors.response.use(
//   (response) => {
//     return response.data;
//   },
//   (error) => {
//     useNotificationStore.getState().addNotification({
//       type: "error",
//       title: "Error",
//       message: error.message ? error.message : "Please check server connection",
//     });

//     return Promise.reject(error);
//   }
// );

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Handle 401 error
        // Clear local storage and redirect to /auth/login
        storage.clearToken(); // Assuming you have a clearToken function
        window.location.href = "/auth/login"; // Redirect to the login page
      } else if (error.response.status === 400) {
        // Handle 400 error
        const errorMessage =
          error.response.data && error.response.data.errors
            ? error.response.data.errors[0].msg
            : "Bad Request";

        useNotificationStore.getState().addNotification({
          type: "error",
          title: "Bad Request",
          message: errorMessage,
        });
      } else {
        useNotificationStore.getState().addNotification({
          type: "error",
          title: "Error",
          message: error.message
            ? error.message
            : "Please check server connection",
        });
      }
    }

    return Promise.reject(error);
  }
);
