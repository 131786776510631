import { Header } from "components/Elements";

import React from "react";
import { DeclinedBidsList } from "../components/DeclinedBidsList";
import { AcceptedBidsList } from "../components/AcceptedBidsList";
import { SubmittedBidsList } from "../components/SubmittedBidsList";

export const Bids = () => {
  return (
    <div>
      <Header label={`Purchase Requests (PRs)`} />
      <SubmittedBidsList />
      <AcceptedBidsList />
      <DeclinedBidsList />
    </div>
  );
};
