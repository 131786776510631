import { Button, ConfirmationDialog } from "components/Elements";
import PropTypes from "prop-types";

import { useAcceptBid } from "../api/acceptBid";

export const AcceptBid = ({ id }) => {
  const acceptBidMutation = useAcceptBid();

  return (
    <>
      <ConfirmationDialog
        icon="warning"
        title="Accept Bid"
        body="Are you sure you want to accept this bid?"
        triggerButton={
          <Button variant="" size="xs">
            Accept
          </Button>
        }
        confirmButton={
          <Button
            isLoading={acceptBidMutation.isLoading}
            type="button"
            className="bg-green-500 hover:bg-green-600"
            onClick={async () =>
              await acceptBidMutation.mutateAsync({ id: id })
            }
          >
            Accept
          </Button>
        }
      />
    </>
  );
};

AcceptBid.propTypes = {
  id: PropTypes.string.isRequired,
};
