import { Button, ConfirmationDialog } from "components/Elements";
import PropTypes from "prop-types";

import { useDeclineBid } from "../api/declineBid";

export const DeclineBid = ({ id }) => {
  const declineBidMutation = useDeclineBid();

  return (
    <>
      <ConfirmationDialog
        icon="warning"
        title="Decline Bid"
        body="Are you sure you want to decline this bid?"
        triggerButton={
          <Button variant="outline" size="xs">
            Deny
          </Button>
        }
        confirmButton={
          <Button
            isLoading={declineBidMutation.isLoading}
            type="button"
            className="bg-red-600"
            onClick={async () =>
              await declineBidMutation.mutateAsync({ id: id })
            }
          >
            Deny
          </Button>
        }
      />
    </>
  );
};

DeclineBid.propTypes = {
  id: PropTypes.string.isRequired,
};
