import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center text-xs text-gray-500 pt-12 pb-8">
      {t("interface.footer_rights")} .{" "}
      <Link to="/terms-and-conditions" className="text-red-500 font-bold">
        {t("interface.link_terms")}
      </Link>{" "}
      .{" "}
      <Link to="/privacy-policy" className="text-red-500 font-bold">
        {t("interface.link_policy")}
      </Link>
    </div>
  );
};
