import React, { useState } from "react";
import { useNotifications } from "../api/getNotifications";
import { Header, Spinner } from "components/Elements";
import { formatDate } from "utils/format";
import { Notification } from "../components/Notification";
import { ChevronDoubleDownIcon } from "@heroicons/react/solid";

export const Notifications = () => {
  const notificationsQuery = useNotifications();
  const [visibleNotifications, setVisibleNotifications] = useState(6);

  if (notificationsQuery.isLoading) {
    return <Spinner />;
  }

  if (!notificationsQuery.data) {
    return null;
  }
  const loadMore = () => {
    setVisibleNotifications(visibleNotifications + 6);
  };

  return (
    <>
      <div>
        <Header label="My Notifications" />
        {notificationsQuery.data
          .slice(0, visibleNotifications)
          .map((not, index) => (
            <Notification
              key={index}
              id={not._id}
              title={not.title}
              route={not.route}
              bid={not.bid}
              post={not.post}
              clicked={not.isRead}
              datetimeCreated={formatDate(not.createdAt)}
              message={not.message}
            />
          ))}
      </div>
      <div className="flex items-center justify-center">
        {visibleNotifications < notificationsQuery.data.length && (
          <button
            className="mt-4 text-flamingo-500 flex flex-row space-x-2 items-center dark:text-white py-2 px-4 rounded-md shadow-md"
            onClick={loadMore}
          >
            <ChevronDoubleDownIcon className="h-4" />
            <span> Load More</span>
          </button>
        )}
      </div>
      {notificationsQuery.data.length === 0 && (
        <div className="text-center p-10 font-normal text-gray-500 my-12">You have no notifications at the moment.</div>
      )}
    </>
  );
};
