import { Listings } from "features/listings/routes/Listings";
import { useAuth } from "lib/auth";
import md5 from "md5";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Profile = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const md5Hash = md5(user.email);

  return (
    <>
      <div className="flex flex-col mt-12">
        <div className="text-2xl text-center mb-4">My Profile</div>
        <img src={`https://www.gravatar.com/avatar/${md5Hash}`} alt="" className="h-32 w-32 rounded-full mx-auto" />
        <div class="inline-flex flex-col space-y-1 items-center justify-center w-full h-40">
          <p class="text-2xl md:text-4xl font-black">{user.name}</p>
          <p class="text-2xl md:text-4xl font-bold">{user.email}</p>
          <p class="text-xl md:text-4xl font-bold">{user.phone}</p>
        </div>
        <Listings />
      </div>
    </>
  );
};
