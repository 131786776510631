import React from "react";

import { useNavigate } from "react-router-dom";

export const MakePayment = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full max-w-screen-md mx-auto p-4">
      <div className="bg-green-200 border border-green-600 text-green-800 p-4 rounded">
        Post was successfully created.
      </div>

      <div className="mt-4">
        <button
          className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => navigate("/app/listings")}
        >
          Proceed to Listings and Pay
        </button>
      </div>
    </div>
  );
};
