import { axios } from "lib/axios";
import { useNotificationStore } from "stores/notifications";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query";

export const createBid = ({ data }) => {
  return axios.post(`/bids`, data);
};

export const useCreateBid = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newPost) => {},
    onError: (_, __, context) => {},
    onSuccess: (data) => {
      queryClient.invalidateQueries("bids");
      addNotification({
        type: "success",
        title: data.msg,
      });
    },
    ...config,
    mutationFn: createBid,
  });
};
