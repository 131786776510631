import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getCategories = () => {
  return axios.get('/posts/categories');
};

export const usePostCategories = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['categories'],
    queryFn: () => getCategories(),
  });
};
