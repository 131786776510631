import { Button, Spinner } from "components/Elements";
import { Form, FormDrawer, InputField } from "components/Form";
import { PencilIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";

import { useAdjustSellingPrice } from "../api/adjustSellingPrice";
import { usePost } from "../api/getPost";
import { usePreferences } from "features/preferences";

export const AdjustSellingPrice = ({ slug }) => {
  const postQuery = usePost({ slug });
  const adjustSellingPriceMutation = useAdjustSellingPrice();
  const preferencesQuery = usePreferences();

  if (adjustSellingPriceMutation.isLoading) {
    return (
      <div
        id="loading-screen"
        className="w-full h-full fixed block top-0 left-0 bg-black opacity-75 z-50"
      >
        <span className="opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
          <Spinner size="sm" />
        </span>
      </div>
    );
  }

  if (!preferencesQuery.data) return null;

  return (
    <>
      <FormDrawer
        size="sm"
        isDone={adjustSellingPriceMutation.isSuccess}
        triggerButton={
          <Button size="sm" startIcon={<PencilIcon className="h-4 w-4" />}>
            <span>Adjust Selling Price</span>
          </Button>
        }
        title="Adjust Selling Price"
        submitButton={
          <Button
            form="adjust-selling-price"
            type="submit"
            size="sm"
            isLoading={adjustSellingPriceMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="adjust-selling-price"
          onSubmit={async (values) => {
            await adjustSellingPriceMutation.mutateAsync({
              data: values,
              postId: postQuery.data._id,
            });
          }}
          options={{
            defaultValues: {
              selling_price: postQuery.data?.selling_price,
            },
          }}
        >
          {({ register, formState }) => (
            <>
              <InputField
                label={`Adjust Selling Price (${preferencesQuery.data?.currency})`}
                error={formState.errors["selling_price"]}
                registration={register("selling_price")}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </>
  );
};

AdjustSellingPrice.propTypes = {
  slug: PropTypes.string.isRequired,
};
