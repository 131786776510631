import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotificationStore } from "stores/notifications";
import { useMutation } from "react-query";

export const approveTrade = ({ id }) => {
  console.log(id);
  return axios.post(`/trades/${id}/accept-incoming`);
};

export const useApproveTrade = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (approvedTrade) => {},
    onError: (_, __, context) => {},
    onSuccess: (data) => {
      queryClient.invalidateQueries("trades");
      addNotification({
        type: "success",
        title: data.msg,
      });
    },
    ...config,
    mutationFn: approveTrade,
  });
};
