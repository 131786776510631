import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getNotifications = () => {
  return axios.get('/notifications/mine');
};

export const useNotifications = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['notifications'],
    queryFn: () => getNotifications(),
  });
};
