const storagePrefix = "msw-db";

const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}-token`));
  },
  getReferralCode: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}-referral-code`));
  },
  setToken: (token) => {
    window.localStorage.setItem(`${storagePrefix}-token`, JSON.stringify(token));
  },
  setReferralCode: (code) => {
    window.localStorage.setItem(`${storagePrefix}-referral-code`, JSON.stringify(code));
  },
  getUserData: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}-user`));
  },
  setUserData: (user) => {
    window.localStorage.setItem(`${storagePrefix}-user`, JSON.stringify(user));
  },
  setSignInMethod: (signInMethod) => {
    window.localStorage.setItem(`signInMethod`, JSON.stringify(signInMethod));
  },
  getSignInMethod: () => {
    return JSON.parse(window.localStorage.getItem(`signInMethod`));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}-token`);
    window.localStorage.removeItem(`signInMethod`);
    window.localStorage.removeItem(`${storagePrefix}-user`);
  },
};

export default storage;
