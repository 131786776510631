import { Navigate, Route, Routes } from 'react-router-dom';
import { Bids } from './Bids';
import { Bid } from './Bid';
import { BidPaymentSuccess } from './BidPaymentSuccess';


export const BidsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Bids />} />
      <Route path=":id" element={<Bid />} />
      <Route path=":id/payment/success" element={<BidPaymentSuccess />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
