import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotificationStore } from "stores/notifications";
import { useMutation } from "react-query";

export const createTrade = ({ data }) => {
  return axios.post(`/trades/new`, data);
};

export const useCreateTrade = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newTrade) => {
      await queryClient.cancelQueries("trades");

      const previousTrades = queryClient.getQueryData("trades");

      queryClient.setQueryData("trades", [
        ...(previousTrades || []),
        newTrade.data,
      ]);

      return { previousTrades };
    },
    onError: (_, __, context) => {
      if (context?.previousTrades) {
        queryClient.setQueryData("trades", context.previousTrades);
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("trades");
      addNotification({
        type: "success",
        title: data.msg,
      });
    },
    ...config,
    mutationFn: createTrade,
  });
};
