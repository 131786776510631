import { useTrades } from "features/trades";
import React from "react";
import { useTranslation } from "react-i18next";
import { BsArrowCounterclockwise } from "react-icons/bs";
import { RiBriefcase3Line } from "react-icons/ri";
import { Link } from "react-router-dom";

export const TradesLink = () => {
  const tradesQuery = useTrades();

  const { t } = useTranslation()

  if (tradesQuery.isLoading) {
    return <></>;
  }

  if (!tradesQuery.data) return null;
  return (
    <Link
      to="/app/trades"
      className="hover:shadow-xl py-2 px-2 text-md flex items-center rounded-full gap-4"
    >
      <div className="relative">
        <BsArrowCounterclockwise className="h-6 w-6 md:h-8 md:w-8" />
        {tradesQuery.data?.filter((trade) => trade.status !== "ACCEPTED")
          .length > 0 && (
          <span className="absolute h-4 w-4 top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"></span>
        )}
      </div>
      <h1 className="hidden md:block cursor-pointer">{t('interface.swaps')}</h1>
    </Link>
  );
};
