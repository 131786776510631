import { Navigate, Route, Routes } from 'react-router-dom';

import { Trade } from './Trade';
import { Trades } from './Trades';
import { AcceptSuccess } from './AcceptSuccess';

export const TradesRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Trades />} />
      <Route path=":tradeId" element={<Trade />} />
      <Route path="accept/success" element={<AcceptSuccess />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
