import { Button } from "components/Elements";
import { Form, InputField } from "components/Form";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useResetPassword } from "../api/resetPassword";
import Swal from "sweetalert2";

export const ResetPasswordForm = () => {
  const resetPasswordMutation = useResetPassword();
  const { token } = useParams();
  const navigate = useNavigate();

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          if (values.password !== values.password_confirmation) {
            Swal.fire({
              icon: "error",
              title: "Password Mismatch",
              text: "The password and confirmation password do not match.",
            });
          }
          values.token = token;
          await resetPasswordMutation.mutateAsync({ data: values });
          navigate("/auth/login");
        }}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="password"
              placeholder="Password"
              required
              error={formState.errors["password"]}
              registration={register("password")}
            />
            <InputField
              type="password"
              placeholder="Confirm Password"
              requred
              error={formState.errors["password_confirmation"]}
              registration={register("password_confirmation")}
            />

            <div>
              <Button
                isLoading={resetPasswordMutation.isLoading}
                type="submit"
                className="w-full text-gray-g9"
              >
                Reset Password
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          <Link
            to="/auth/login"
            className="font-medium text-orange-o10 hover:text-blue-500"
          >
            Go to Login
          </Link>
        </div>
      </div>
    </div>
  );
};
