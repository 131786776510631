import React from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { usePost } from "../api/getPost";
import { Button, Spinner } from "components/Elements";
import { ChevronRight } from "react-feather";
import { Slide } from "react-slideshow-image";
import { UPLOADS_URL } from "config";
import { DeletePost } from "..";
import { AdjustSellingPrice } from "../components/AdjustSellingPrice";
import { PromotePost } from "../components/PromotePost";
import { TrashIcon } from "@heroicons/react/outline";

export const Listing = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const { slug } = useParams();
  const postQuery = usePost({ slug });

  if (postQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  }

  const shareWhatsApp = () => {
    const textToShare = encodeURIComponent(postQuery.data.title);
    const urlToShare = encodeURIComponent(window.location.href);
    window.open(
      `https://api.whatsapp.com/send?text=${textToShare}%20${urlToShare}`,
      "_blank"
    );
  };

  const shareTwitter = () => {
    const textToShare = encodeURIComponent(postQuery.data.title);
    const urlToShare = encodeURIComponent(window.location.href);
    window.open(
      `https://twitter.com/intent/tweet?text=${textToShare}&url=${urlToShare}`,
      "_blank"
    );
  };

  const shareFacebook = () => {
    const urlToShare = encodeURIComponent(window.location.href);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`,
      "_blank"
    );
  };

  if (!postQuery.data) return null;
  return (
    <div className="bg-white rounded-2xl shadow-md p-4 md:p-12">
      <div className="mb-4 p-4 rounded-md">
        <div className="flex flex-row items-center space-x-2">
          <Link to="/app/listings" className="text-xl font-bold">
            My Listings
          </Link>
          <ChevronRight className="h-4 w-4" />
          <div className="">{postQuery.data?.title}</div>
        </div>
      </div>
      <div className="md:grid md:grid-cols-2 md:gap-2">
        <div className="">
          <Slide arrows={true} autoplay={true} canSwipe={true}>
            {postQuery.data?.images?.map((image, q) => (
              <div key={q} className="md:mx-8 flex items-center justify-center ">
                <img
                  src={
                    image
                      ? UPLOADS_URL + "/" + image
                      : UPLOADS_URL + "/" + "default-image.png"
                  }
                  alt="post"
                  className=" h-[60vh] flex items-center justify-center rounded-2xl shadow"
                />
              </div>
            ))}
          </Slide>
        
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
          <div>
            <h2 className="text-2xl font-semibold">{postQuery.data?.title}</h2>
            <p className="text-gray-600 text-lg">
              Category: {postQuery.data?.category}
            </p>
            <p className="text-gray-600 text-lg">
              Description: {postQuery.data?.description}
            </p>
            <p className="text-gray-600 text-lg">
              Location: {postQuery.data?.pick_up_location}
            </p>
            {postQuery.data?.selling_price && (
              <p className="text-gray-600 text-lg">
                Selling Price: {postQuery.data?.selling_price} UGX
              </p>
            )}
            {postQuery.data?.original_price && (
              <p className="text-gray-600 text-lg">
                Original Price: {postQuery.data?.original_price} UGX
              </p>
            )}
            <p className="text-gray-600 text-lg">
              Contact: {postQuery.data?.contact}
            </p>
            <p className="text-gray-600 text-lg">
              Period of Usage: {postQuery.data?.period_of_usage}
            </p>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="text-2xl font-semibold">Actions</div>
            <div className="flex flex-col space-y-2">
              <DeletePost
                id={postQuery.data?._id}
                triggerButton={
                  <Button variant="outline" startIcon={<TrashIcon className="h-4 w-4" />}>
                    Remove this item
                  </Button>
                }
              />
              <AdjustSellingPrice slug={postQuery.data?.slug} />
              {/* <PromotePost slug={postQuery.data?.slug} /> */}
              {/* <Button variant="outline" size="sm">
                <span>Promote Post</span>
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
