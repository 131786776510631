import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotificationStore } from "stores/notifications";
import PropTypes from "prop-types";
import { useMutation } from "react-query";

export const createPost = ({ data }) => {
  return axios.post(`/posts`, data);
};

createPost.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }).isRequired,
};

export const useCreatePost = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newPost) => {
      await queryClient.cancelQueries("myListings");
      const previousPosts = queryClient.getQueryData("myListings");

      queryClient.setQueryData("myListings", [
        ...(previousPosts || []),
        newPost.data,
      ]);

      return { previousPosts };
    },
    onError: (_, __, context) => {
      if (context?.previousPosts) {
        queryClient.setQueryData("myListings", context.previousPosts);
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("myListings");
      addNotification({
        type: "success",
        title: data.msg,
      });
    },
    ...config,
    mutationFn: createPost,
  });
};