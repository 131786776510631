import logo from "assets/images/logo.svg";

import { Head } from "components/Head";
import PropTypes from "prop-types";

export const Layout = ({ children, title }) => {
  return (
    <>
      <Head title={title} />
      <div className=" flex flex-col justify-center py-2 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md mb-8">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 dark:text-white">
            {title}
          </h2>
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="py-8 px-4 sm:px-10 bg-white rounded-md shadow-md">{children}</div>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
