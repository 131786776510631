import { Button } from "components/Elements";
import { CheckboxField, Form, FormDrawer, InputField } from "components/Form";
import { useMemo, useState } from "react";
import { useAuth } from "lib/auth";
import { useCreatePost } from "features/listings";
import { useCategories } from "../api/getCategories";
import MissClutterSvg from "assets/images/miss-clutter.png";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { usePreferences } from "features/preferences";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { useCreateCategory } from "../api/createCategory";

export const CreatePost = () => {
  const createPostMutation = useCreatePost();
  const createCategoryMutation = useCreateCategory();
  const categoriesQuery = useCategories();
  const preferencesQuery = usePreferences();
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [images, setImages] = useState(null);
  const { user } = useAuth();

  const filteredOptions = useMemo(() => {
    if (!categoriesQuery.data) {
      return []; // Return an empty array when categoriesQuery.data is null
    }

    const lowercasedInput = searchInput.toLowerCase();

    // Check if the searchInput has at least 2 characters before filtering
    if (searchInput.length < 2) {
      return [];
    }

    return categoriesQuery.data.filter((option) =>
      option.title?.includes(lowercasedInput)
    );
  }, [categoriesQuery.data, searchInput]);

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length !== 4) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select exactly 4 images.",
      });
      event.preventDefault();
    } else {
      setImages(files);
    }
  };

  if (!categoriesQuery.data && !preferencesQuery.data) return null;

  // console.log(categoriesQuery.data)

  return (
    <>
      <FormDrawer
        isDone={createPostMutation.isSuccess}
        size="sm"
        triggerButton={
          <div className="hover:text-orange-500 hover:cursor-pointer bg-white border border-gray-200 rounded-2xl p-4 md:p-10 flex flex-row items-center justify-between px-6 md:px-24">
            <div className="pr-8 md:pr-0">
              <div className="font-bold text-xl">
                {t("interface.button_header")}
              </div>
              <div className="text-sm">{t("interface.exchange_or_sell")}</div>
            </div>
            <div className="">
              <img src={MissClutterSvg} alt="miss clutter" className="w-24" />
            </div>
          </div>
        }
        title={t("interface.dejunking")}
        submitButton={
          <Button
            form="create-post"
            type="submit"
            size="sm"
            isLoading={createPostMutation.isLoading}
          >
            {t("interface.submit")}
          </Button>
        }
      >
        <Form
          id="create-post"
          onSubmit={async (values) => {
            const {
              title,
              description,

              pick_up_location,
              period_of_usage,
              has_receipt,
              selling_price,
              original_price,
            } = values;
            const bodyFormData = new FormData();
            if (images) {
              for (let i = 0; i < images.length; i++) {
                bodyFormData.append("images", images[i]);
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: t("interface.exchange_or_sell"),
              });
              return;
            }
            bodyFormData.append("title", title);
            bodyFormData.append("description", description);
            bodyFormData.append("category", selectedCategory);
            bodyFormData.append("pick_up_location", pick_up_location);
            bodyFormData.append("period_of_usage", period_of_usage);
            bodyFormData.append("has_receipt", has_receipt);
            bodyFormData.append("selling_price", selling_price);
            bodyFormData.append("original_price", original_price);
            bodyFormData.append("for_barter", true);
            bodyFormData.append("contact", user.phone);
            await createPostMutation.mutateAsync({ data: bodyFormData });
          }}
          // schema={schema}
        >
          {({ register, formState, watch }) => (
            <div className="flex flex-col space-y-2 mb-8">
              <InputField
                label={`${t("interface.title")} *`}
                error={formState.errors["title"]}
                registration={register("title")}
                placeholder={t("interface.title_description")}
              />
              <div className="">
                <div className="mx-2  mb-3">
                  {" "}
                  <span className="text-sm">
                    {t("interface.select_images")} *
                  </span>
                  <br />
                  <span className="text-xs">
                    {t("interface.select_4_images")}.
                  </span>
                </div>
                <input
                  type="file"
                  label="Images"
                  className="border border-gray-200"
                  // accept="image/jpeg, image/png image/heic"
                  multiple
                  onChange={handleFileChange}
                />
                {formState.errors["images"] && (
                  <p className="text-red-500">
                    {formState.errors["images"].message}
                  </p>
                )}
              </div>
              <InputField
                label={`${t("drawers.reason")} *`}
                error={formState.errors["description"]}
                registration={register("description")}
                placeholder={t("drawers.reason_description")}
              />
              <div className="">{t("drawers.category")} *</div>
              <CreatableSelect
                name="category"
                options={filteredOptions.map((cat) => ({
                  label: cat.title,
                  value: cat.title,
                }))}
                onChange={handleCategoryChange}
                defaultValue={watch('category')}
                onInputChange={inputValue => setSearchInput(inputValue)}
                onCreateOption={async (newOption) => {
                  setSelectedCategory(newOption);
                  await createCategoryMutation.mutateAsync({
                    data: { title: newOption },
                  });

                  handleCategoryChange(newOption);
                }}
                maxMenuHeight={100}
                value={selectedCategory}
                isSearchable={true}
                placeholder={`${t("drawers.select_categories")}...`}
              />
              <InputField
                label={`${t("drawers.period_of_usage")} *`}
                placeholder={t("drawers.usage_description")}
                error={formState.errors["period_of_usage"]}
                registration={register("period_of_usage")}
              />
              <div className="border border-red-500 bg-red-100 mb-4 p-3 rounded-md text-gray-800 text-xs">
                {t("drawers.price_description")}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <InputField
                  label={`${t("drawers.original_price")} (${
                    preferencesQuery.data?.currency
                  })`}
                  type="number"
                  placeholder=""
                  error={formState.errors["original_price"]}
                  registration={register("original_price")}
                />
                <InputField
                  label={`${t("drawers.selling_price")} (${
                    preferencesQuery.data?.currency
                  })`}
                  type="number"
                  placeholder=""
                  error={formState.errors["selling_price"]}
                  registration={register("selling_price")}
                />
              </div>
              <InputField
                label={`${t("drawers.your_location")} *`}
                error={formState.errors["pick_up_location"]}
                registration={register("pick_up_location")}
              />
              <div className="space-y-4">
                <CheckboxField
                  label={t("drawers.receipt")}
                  error={formState.errors["has_receipt"]}
                  registration={register("has_receipt")}
                />

                <div className="border border-red-500 bg-red-100 mb-4 p-3 rounded-md text-gray-800 text-sm">
                  <span className="font-bold">{t("drawers.disclaimer")}</span>
                  <br />
                  {t("drawers.disclaimer_description")}{" "}
                  <Link
                    className="text-orange-500 hover:cursor-pointer"
                    to="/terms-and-conditions"
                  >
                    {t("interface.link_terms")}
                  </Link>{" "}
                  , and{" "}
                  <Link
                    className="text-orange-500 hover:cursor-pointer"
                    to="/privacy-policy"
                  >
                    {t("interface.link_policy")}
                  </Link>{" "}
                  {t("drawers.to_know_more")}.
                </div>
              </div>
            </div>
          )}
        </Form>
      </FormDrawer>
    </>
  );
};
