import { MainLayout } from "components/Layouts";
import { ResetPasswordForm } from "../components/ResetPasswordForm";
import { Layout } from "../components/Layout";

export const ResetPassword = () => {
  return (
    <MainLayout title="Reset Password">
      <Layout title="Reset Password">
        <ResetPasswordForm />
      </Layout>
    </MainLayout>
  );
};
