import { Button } from "components/Elements";
import React from "react";
import { useNavigate } from "react-router-dom";

export const AcceptSuccess = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex flex-row justify-end mb-4">
        <Button onClick={() => navigate("/app")} size="xs">
          Back to trades
        </Button>
      </div>
      <div className="bg-orange-100 p-8 rounded-md text-orange-500 font-bold text-2xl text-center mb-4">
        Great work!
      </div>
      <div className="text-center font-bold">You've accepted a trade</div>
      <div className="text-center font-light">
        The initiator was notified and will call you as soon as possible.
      </div>
    </div>
  );
};
