import { MainLayout } from "components/Layouts";
import React from "react";

export const TermsAndConditions = () => {
  window.scrollTo(0, 0);

  return (
    <MainLayout>
      <div className="flex flex-col mt-12 bg-white rounded-2xl shadow p-4 md:p-12 text-sm">
        <div className="text-2xl mb-3">TERMS AND CONDITIONS</div>
        <div className="mb-3">
          Welcome to Dejunk! These Terms and Conditions ("Agreement") govern
          your use of the Dejunk application ("App") and the services provided
          by Dejunk ("Services"). By using the App and Services, you agree to be
          bound by this Agreement. If you do not agree to this Agreement, please
          do not use the App or Services.
        </div>
        <div className="mb-3">
          <div className="text-xl">User Account and Registration</div>
          <ul>
            <li>
              1.1 In order to use the App and Services, you must create an
              account and provide accurate and complete information.
            </li>
            <li>
              1.2 You are responsible for maintaining the confidentiality of
              your account and password and for all activities that occur under
              your account.
            </li>
            <li>
              1.3 Dejunk reserves the right to suspend or terminate your account
              at any time for any reason, without notice.
            </li>
          </ul>
        </div>
        <div className="mb-3">
          <div className="text-xl">Use of the App and Services</div>
          <ul>
            <li>
              2.1 You may use the App and Services only for lawful purposes and
              in accordance with this Agreement.
            </li>
            <li>
              2.2 You may not use the App or Services to infringe on the
              intellectual property rights of others or to engage in any illegal
              activity.
            </li>
            <li>
              2.3 You are solely responsible for any content you upload to the
              App, and Dejunk reserves the right to remove any content that
              violates this Agreement or is otherwise objectionable.
            </li>
          </ul>
        </div>
        <div className="mb-3">
          <div className="text-xl">Payments and Fees</div>
          <ul>
            <li>
              3.1 You are responsible for any fees associated with using the
              payment gateway, including any transaction or currency exchange
              fees.
            </li>
            <li>
              3.2 Dejunk is not responsible for any disputes or issues that may
              arise from payment transactions, and you agree to indemnify and
              hold harmless Dejunk from any claims or damages arising from such
              disputes.
            </li>
          </ul>
        </div>
        <div className="mb-3">
          <div className="text-xl">Limitation of Liability</div>
          <ul>
            <li>
              4.1 Dejunk is not responsible for any damages or losses that may
              occur due to your use of the App or Services.
            </li>
            <li>
              4.2 Dejunk does not guarantee the quality, safety, or legality of
              items listed on the App, and you agree to use the App and Services
              at your own risk.
            </li>
            <li>
              4.3 Dejunk is not liable for any damages or losses that may result
              from your interactions with other users of the App or Services.
            </li>
          </ul>
        </div>
        <div className="mb-3">
          <div className="text-xl">Indemnification</div>
          <ul>
            <li>
              5.1 You agree to indemnify and hold harmless Dejunk, its
              affiliates, and its officers, directors, employees, and agents
              from any claims, damages, or losses arising from your use of the
              App or Services, or from your violation of this Agreement.
            </li>
          </ul>
        </div>
        <div className="mb-3">
          <div className="text-xl">Changes to the Agreement</div>
          <ul>
            <li>
              6.1 Dejunk reserves the right to modify this Agreement at any
              time, and any changes will be effective immediately upon posting.
            </li>
            <li>
              6.2 Your continued use of the App or Services after any changes to
              this Agreement will constitute your acceptance of the modified
              Agreement.
            </li>
          </ul>
        </div>
        <div className="mb-3">
          <div className="text-xl">Governing Law</div>
          <ul>
            <li>
              7.1 This Agreement shall be governed by and construed in
              accordance with the laws of Uganda.
            </li>
            <li>
              7.2 Any dispute arising from or related to this Agreement shall be
              resolved through arbitration in accordance with the laws of
              Uganda.
            </li>
          </ul>
        </div>
        <div className="mb-3">
          <div className="text-xl">Contact Us</div>
          <ul>
            <li>
              8.1 If you have any questions or concerns about this Agreement,
              please contact us at <a href="https://mailto:support@dejunk.app" className="text-red-500">support@dejunk.app</a> .
            </li>
            <li>
              By using the App or Services, you acknowledge that you have read,
              understood, and agree to be bound by this Agreement.
            </li>
          </ul>
        </div>
        <div className="mb-3">
          Last Updated:{" "}
          <span
            className="
                        font-semibold text-sm text-red-600"
          >
            11 . November . 2023
          </span>
        </div>
      </div>
    </MainLayout>
  );
};
