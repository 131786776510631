import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getPost = ({ postId }) => {
  return axios.get(`/posts/${postId}`);
};

export const usePost = ({ postId, config }) => {
  return useQuery({
    ...config,
    queryKey: ["post", postId],
    queryFn: () => getPost({ postId }),
  });
};
