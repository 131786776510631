import { useAuth } from "lib/auth";
import { axios } from "lib/axios";
// import { queryClient } from "lib/react-query";
// import { useNotificationStore } from "stores/notifications";
import PropTypes from "prop-types";
import { useMutation } from "react-query";

export const completeRegistration = ({ userId, data }) => {
  return axios.patch(`/users/${userId}`, data);
};

completeRegistration.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
};

export const useCompleteRegistration = ({ config } = {}) => {
  const { refetchUser } = useAuth();
  return useMutation({
    onSuccess: (data) => {
      refetchUser();
    },
    ...config,
    mutationFn: completeRegistration,
  });
};
