import { Button, Header, Spinner } from "components/Elements";
import { useAuth } from "lib/auth";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTrades } from "../api/getTrades";
import { ArrowLeft, ArrowRight } from "react-feather";
import { useApproveTrade } from "../api/approveTrade";
import { useState } from "react";

export const Trades = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const tradesQuery = useTrades();

  const approveTradeMutation = useApproveTrade();

  if (tradesQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!tradesQuery.data) return null;

  return (
    <div>
      <Header label="Swaps" />
      <div className="">
        {tradesQuery.data
          .filter((trade) => trade.status !== "ACCEPTED")
          .map((trade, index) => (
            <div
              key={index}
              className="border bg-white rounded-2xl border-dotted border-gray-300 p-6 shadow"
            >
              {trade.status === "REQUESTED" && (
                <span className="text-xs text-green-500 bg-green-100 p-1">
                  New
                </span>
              )}
              {trade.buying_price && (
                <div className="flex flex-row items-center space-x-2 mb-2">
                  <div className="text-xs text-gray-500">
                    {trade.initiator._id}
                  </div>
                  <div className="text-xs">
                    is interested in buying this item.
                  </div>
                </div>
              )}
              <div className="flex flex-row items-center space-x-2 mb-3">
                <div className="font-bold">
                  {trade.initiatorPost ? (
                    <div className="flex flex-row items-center space-x-2">
                      <span>{trade.initiatorPost?.title}</span>{" "}
                      <ArrowRight className="h-4" />{" "}
                      <span>{trade.receiverPost?.title}</span>
                    </div>
                  ) : (
                    <span>{trade.receiverPost?.title}</span>
                  )}
                </div>
                {trade.buying_price && (
                  <div className="text-gray-600"> at {trade.buying_price}</div>
                )}
              </div>
              <div className="flex flex-row items-center space-x-2">
                <Button
                  size="xs"
                  onClick={async () => {
                    await approveTradeMutation.mutateAsync({
                      id: trade._id,
                    });
                    navigate("/app/trades/accept/success");
                  }}
                >
                  Accept
                </Button>
                <Button size="xs">Deny</Button>
                <Button size="xs">Add Topup</Button>
                <Button size="xs">Request Increase Bid</Button>
              </div>
            </div>
          ))}
      </div>
      {tradesQuery.data.length === 0 && (
        <div className="text-center p-10 font-normal text-gray-500 my-12">
          You have no ongoing trades at the moment.
        </div>
      )}
    </div>
  );
};
