import React from "react";
import { useParams } from "react-router-dom";
import { usePost } from "../api/getPost";
import { Spinner } from "components/Elements";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";

import { FLW_PUBLIC_KEY } from "config";
import { useAuth } from "lib/auth";
import DejunkLogo from "assets/images/dejunk-logo.svg";

export const PayForPost = () => {
  const { slug } = useParams();
  const postQuery = usePost({ slug });
  const { user } = useAuth();

  // Ensure hooks are called unconditionally
  const postData = postQuery.data; // Assuming your post data is accessible this way

  const config = {
    public_key: FLW_PUBLIC_KEY,
    tx_ref: Date.now(),
    amount: postQuery.data?.dejunk_share_to_pay,
    currency: "UGX",
    payment_options: "card,mobilemoney,ussd",
    order_id: postQuery.data?._id,
    order_desc: `Payment for "${postQuery.data?.title}"`,
    redirect_url: `http://localhost:3000/app/listings/${slug}/payment-successful`,
    customer: {
      email: user.email,
      phone_number: user.phone,
      name: user.name,
    },
    customizations: {
      title: "Dejunk Payment",
      description: `Payment for "${postQuery.data?.title}"`,
      logo: DejunkLogo,
    },
  };
  const handleFlutterPayment = useFlutterwave(config);

  if (postQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <div className="font-bold text-2xl mx-3 text-orange-500">Checkout</div>
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-4">
          <h1 className="text-2xl font-semibold">{postData.title}</h1>
          <p className="text-gray-500">{postData.description}</p>
        </div>
        <div className="p-4 border-t border-gray-200">
          <div className="flex justify-between items-center">
            <span className="text-gray-600">Price:</span>
            <span className="text-lg font-semibold text-orange-500">
              {postData.dejunk_share_to_pay} UGX
            </span>
          </div>
          <div className="flex justify-between items-center mt-2">
            <span className="text-gray-600">Contact:</span>
            <span className="text-lg font-semibold">{postData.contact}</span>
          </div>
          <div className="flex justify-between items-center mt-2">
            <span className="text-gray-600">Pickup Location:</span>
            <span className="text-lg font-semibold text-end">
              {postData.pick_up_location}
            </span>
          </div>
        </div>
        <div className="p-4 border-t border-gray-200">
          <button
            type="button"
            onClick={() =>
              handleFlutterPayment({
                callback: (response) => {
                  console.log(response);
                  closePaymentModal();
                },
                onClose: () => {},
              })
            }
            className="bg-orange-500 hover-bg-orange-600 text-white font-semibold py-2 px-4 rounded"
          >
            Pay Now
          </button>
        </div>
      </div>
    </div>
  );
};
