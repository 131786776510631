import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import storage from "utils/storage";
import Swal from "sweetalert2";
import { auth } from "lib/firebaseAuth";
import { checkAccountStatus } from "./checkAccountStatus";
import { queryClient } from "lib/react-query";
import { getUser } from "./getUser";

export const loginWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  provider.addScope("profile");
  provider.addScope("email");

  return signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = result.user?.accessToken;
      const user = result.user;
      const signInMethod = credential?.signInMethod;
      return handleUserResponse({ token, user, signInMethod });
    })
    .catch((error) => {
      if (error) {
        Swal.fire({
          icon: "error",
          title: "Google Authentication Error",
          text: error.message,
        });
      }
    });
};

async function handleUserResponse(data) {
  const { token, user, signInMethod } = data;
  storage.setToken(token);
  storage.setSignInMethod(signInMethod);
  await checkAccountStatus({
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
    active: true,
  });

  const updatedUserData = await getUser();
  storage.setUserData(updatedUserData);

  await queryClient.invalidateQueries("auth-user");
  return updatedUserData;
}
