import { Spinner } from "components/Elements";
import { useAuth } from "lib/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CreatePost } from "../components/CreatePost";
import { usePosts } from "../api/getPosts";
import { Post } from "../components/Post";
import { Categories } from "../components/Categories";
import { AddPhoneNumber } from "features/profile";
import { useReferAFriend } from "../api/referAFriend";
import storage from "utils/storage";
import { usePreferences } from "features/preferences";
import { useTranslation } from "react-i18next";

export const Dejunk = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const postsQuery = usePosts();
  const { t } = useTranslation();
  const [activeCategory, setActiveCategory] = useState("all");

  const referAFriendMutation = useReferAFriend();

  const preferencesQuery = usePreferences();

  useEffect(() => {
    const fetchData = async () => {
      if (!user.displayName) {
        navigate("/complete-registration");
        return;
      }

      if (storage.getReferralCode()) {
        try {
          await referAFriendMutation.mutateAsync({
            data: {
              userId: user._id,
              referralCode: storage.getReferralCode(),
            },
          });
        } catch (error) {
          // Handle error, log, or do something appropriate
          console.error("Error while processing referral code:", error);
        }
      }
    };

    if (user.displayName) {
      fetchData();
    }
  }, [
    user.displayName,
    navigate,
    referAFriendMutation,
    user._id,
    preferencesQuery.data?.lang,
  ]);

  if (postsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!postsQuery.data) return null;

  return (
    <div>
      {!user.phone ? <AddPhoneNumber /> : <CreatePost />}

      <div className="py-2 flex flex-row items-center justify-end mb-4">
        <Categories
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
      </div>
      {postsQuery.data?.length === 0 && (
        <div className="flex items-center justify-center font-bold text-gray-500 text-xl text-center p-10 my-4">
          <div>{t("interface.no_posts")}</div>
        </div>
      )}
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {activeCategory === "all" &&
          postsQuery.data
            .filter((post) => !post.in_ongoing_trade)
            .filter((post) => post.bids < 5)
            .filter((post) => user._id !== post.user)
            .map((post) => (
              <Post
                key={post._id}
                id={post._id}
                userId={post.user}
                image={post.images[0]}
                {...post}
              />
            ))}
        {postsQuery.data
          .filter((post) => post.category === activeCategory)
          .filter((post) => !post.in_ongoing_trade)
          .filter((post) => user._id !== post.user)
          .map((post, index) => (
            <Post
              key={post._id}
              id={post._id}
              userId={post.user}
              image={post.images[0]}
              {...post}
            />
          ))}
      </div>
    </div>
  );
};
