import { MainLayout } from "components/Layouts";
import { Layout } from "../../auth/components/Layout";

export const ThankYou = () => {
  return (
    <MainLayout title="Thank you">
      <Layout title="Thank you">
        <div className="text-gray-500 mb-4">
          Thank you for joining our waiting list. You will receive an email as soon as the mobile app has been released. You've also signed up for the beta list for testing the mobile application.
        </div>
      </Layout>
    </MainLayout>
  );
};
