import { Button, Drawer } from "components/Elements";
import { useDisclosure } from "hooks/useDisclosure";
import { useAuth } from "lib/auth";
import React, { useState } from "react";
import { Menu } from "react-feather";
import { useCategories } from "../api/getCategories";
import { useTranslation } from "react-i18next";

export const Categories = ({ activeCategory, setActiveCategory }) => {
  const { close, open, isOpen } = useDisclosure();
  const { user } = useAuth();
  const {t } = useTranslation()
  const categoriesQuery = useCategories();

  if (!categoriesQuery.data) return null;

  return (
    <div className="">
      <div className="flex flex-row justify-end">
        <button
          type="button"
          size="sm"
          onClick={open}
          className="flex bg-white border border-gray-200 rounded-2xl flex-row items-center space-x-2 mt-3 p-3"
        >
          <Menu />
          <span> {t('interface.all_categories')}</span>
        </button>
      </div>
      <Drawer
        isOpen={isOpen}
        onClose={close}
        title={`${user ? `Hi ${user?.displayName}!` : `Barter World`}`}
        size="sm"
        renderFooter={() => <></>}
      >
        <div className="font-bold mb-4">
          You can select from the list of categories available.
        </div>
        {categoriesQuery.data.length ? (
          <ul className="space-y-1">
            <li
              onClick={() => {
                setActiveCategory("all");
                close();
              }}
              className={`cursor-pointer hover:text-orange-500 ${
                activeCategory === "all" ? "font-bold text-orange-500" : ""
              }`}
            >
              All
            </li>
            {categoriesQuery.data.map((cat, index) => (
              <li
                key={index}
                onClick={() => {
                  setActiveCategory(cat.title);
                  close();
                }}
                className={`cursor-pointer hover:text-orange-500 ${
                  activeCategory === cat.title ? "font-bold text-orange-500" : ""
                }`}
              >
                {cat.title}
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-gray-400">Currently no categories listed.</div>
        )}
      </Drawer>
    </div>
  );
};
