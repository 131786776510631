import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "lib/auth";
import { useEffect } from "react";
import {  Spinner } from "components/Elements";
import { MainLayout } from "components/Layouts";
import { useTranslation } from "react-i18next";
import { loginWithGoogle } from "features/auth";
import { usePosts } from "features/dejunk";
import { UPLOADS_URL } from "config";
import { MapPin } from "react-feather";

export const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const postsQuery = usePosts();
  const [loginWithGoogleLoading, setLoginWithGoogleLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/app");
    }
  }, [user, navigate]);

  const handleGoogleLogin = async () => {
    setLoginWithGoogleLoading(true);
    await loginWithGoogle();
    navigate("/app");
    setLoginWithGoogleLoading(false);
  };

  if (postsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!postsQuery.data) return null;

  return (
    <MainLayout>
      <div onClick={handleGoogleLogin} className="cursor-pointer bg-white mb-4 rounded-md shadow p-10">
        <div className="font-black text-gray-800 text-3xl">
          Clear your space today
        </div>
        <div className="text-red-500">Get started for free!</div>
        <div className="text-sm text-gray-500">
          Create a free account and start dejunking right away
        </div>
      </div>
      {postsQuery.data?.length === 0 && (
        <div className="flex items-center justify-center font-bold text-gray-500 text-xl text-center p-10 my-4">
          <div>{t("interface.no_posts")}</div>
        </div>
      )}
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {postsQuery.data
          .filter((post) => !post.in_ongoing_trade)
          .map((post, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-2xl overflow-hidden transform transition-transform duration-300 hover:scale-105"
            >
              <Link onClick={undefined}>
                <img
                  src={
                    post.images[0]
                      ? UPLOADS_URL + "/" + post.images[0]
                      : "https://placehold.co/600x400"
                  }
                  alt="post"
                  className="w-full h-64 object-cover"
                />
              </Link>
              <div className="px-4 py-4">
                <Link
                  onClick={undefined}
                  className="text-sm font-semibold text-gray-800 hover:text-orange-600"
                >
                  {post.title}
                </Link>
                <div className="flex flex-row items-center justify-between space-x-2">
                  <div className="flex items-center text-gray-600 text-xs my-2">
                    <MapPin className="h-4 text-orange-500" />
                    <span>{post.pick_up_location}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </MainLayout>
  );
};
