import { Button } from "components/Elements";
import {
  Form,
  FormDrawer,
  InputField,
  PhoneInputField,
  TextAreaField,
} from "components/Form";
import { Authorization, ROLES } from "lib/authorization";
import {
  ChevronDoubleDownIcon,
  ChevronDoubleRightIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import MissClutterSvg from "assets/images/miss-clutter.png";
import { useCreatePost } from "../api/createPost";
import { useState } from "react";

import { useAuth } from "lib/auth";
import { useNavigate } from "react-router-dom";
import { X } from "react-feather";
import { usePostCategories } from "../api/getCategories";

export const CreatePost = () => {
  const createPostMutation = useCreatePost();
  const categoriesQuery = usePostCategories();
  const [stateCategory, setStateCategory] = useState("");
  const [images, setImages] = useState(null);
  const { user } = useAuth();

  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const files = event.target.files;

    setImages(files);
  };

  if (!categoriesQuery.data) return null;

  return (
    <>
      <FormDrawer
        isDone={createPostMutation.isSuccess}
        size="md"
        triggerButton={
          <div className="hover:bg-gray-200 hover:cursor-pointer border border-gray-200 rounded-2xl p-4 md:p-10 flex flex-row items-center justify-between px-6 md:px-24">
            <div className="pr-8 md:pr-0">
              <div className="font-bold text-xl mb-2">
                What would you like to sell today?
              </div>
              <div className="text-sm">
                Post unwanted items in your household today and sell them off
                easily.
              </div>
            </div>
            <div className="">
              <img src={MissClutterSvg} alt="miss clutter" className="w-24" />
            </div>
          </div>
        }
        title={`What's for sale today?`}
        submitButton={
          <Button
            form="create-post"
            type="submit"
            size="sm"
            isLoading={createPostMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="create-post"
          onSubmit={async (values) => {
            const {
              title,
              description,
              category,
              original_price,
              selling_price,
              pick_up_location,
            } = values;
            const bodyFormData = new FormData();
            if (images) {
              for (let i = 0; i < images.length; i++) {
                bodyFormData.append("images", images[i]);
              }
            }
            bodyFormData.append("title", title);
            bodyFormData.append("description", description);
            bodyFormData.append("category", category);
            bodyFormData.append("original_price", original_price);
            bodyFormData.append("selling_price", selling_price);
            bodyFormData.append("pick_up_location", pick_up_location);
            bodyFormData.append("contact", user.phone);
            await createPostMutation.mutateAsync({ data: bodyFormData });
            navigate("/app/listings/make-payment");
          }}
        >
          {({ register, formState }) => (
            <>
              <InputField
                label="Title"
                error={formState.errors["title"]}
                registration={register("title")}
                description="Provide a proper title for your item"
              />
              <InputField
                label="Description"
                error={formState.errors["description"]}
                registration={register("description")}
                description="Provide a well detailed description of your item"
              />
              {stateCategory ? (
                <div className="">
                  <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                    <div className="mx-8 mb-1">
                      <div>Category</div>
                    </div>
                    <div className="text-xs">
                      You can add your own category or select from the list
                      below
                    </div>
                    <div className="mt-1">
                      <div className="flex flex-row items-center justify-between appearance-none block w-full px-8 py-3 border border-orange-200 bg-white rounded-md placeholder-gray-400 focus:bg-white focus:outline-none focus:ring-0 sm:text-sm">
                        <span>{stateCategory}</span>
                        <button
                          type="button"
                          onClick={() => setStateCategory(null)}
                          className="flex flex-row items-center space-x-1"
                        >
                          <X className="h-4" /> <span>Clear</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <InputField
                  label="Category"
                  error={formState.errors["category"]}
                  registration={register("category")}
                  description="You can add your own category or select from the list below"
                />
              )}
              <div>
                <div className="mb-2 text-sm">
                  Select from list of categories that already exist.
                </div>
                {categoriesQuery.data.map((cat, index) => (
                  <button
                    type="button"
                    onClick={() => setStateCategory(cat)}
                    className="bg-orange-100 mx-1 text-orange-500 text-xs p-1 px-3 rounded-md"
                  >
                    {cat}
                  </button>
                ))}
              </div>
              <InputField
                label="Original Price"
                error={formState.errors["original_price"]}
                registration={register("original_price")}
              />
              <InputField
                label="Selling Price"
                error={formState.errors["selling_price"]}
                registration={register("selling_price")}
              />
              <TextAreaField
                label="Pick up location"
                error={formState.errors["pick_up_location"]}
                registration={register("pick_up_location")}
              />
              <input
                type="file"
                label="Images"
                accept="image/jpeg, image/png"
                multiple
                onChange={handleFileChange}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </>
  );
};
