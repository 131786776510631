import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotificationStore } from "stores/notifications";
import { useMutation } from "react-query"; // Import the useMutation hook

export const unReadNotification = async ({ notification_id }) => {
  return await axios.get(`/notifications/unread?id=${notification_id}`);
};

export const useUnReadNotification = ({ notification_id, config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation(
    async ({ notification_id }) => {
      const data = await unReadNotification({ notification_id });
      return data;
    },
    {
      onMutate: async () => {
        // This function is executed before the mutation starts.
        // You can use it to do something with the previous data before it's modified.
        // For example, you can store the previous data in case you need to roll back the mutation on error.
      },
      onError: (error, variables, context) => {
        // This function is executed when the mutation encounters an error.
        // You can use it to handle errors and revert any changes made in onMutate if needed.
        // The error argument contains the error object, variables contain the mutation arguments,
        // and context contains any data you returned from onMutate.
        console.error("Mutation Error:", error);
      },
      onSuccess: (data, variables, context) => {
        // This function is executed when the mutation is successful.
        // You can use it to update the UI or perform other actions after the mutation.
        queryClient.invalidateQueries("notifications");
        addNotification({
          type: "success",
          title: data.msg,
        });
      },
      ...config,
    }
  );
};
