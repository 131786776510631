import { TrashIcon } from "@heroicons/react/outline";
import { UPLOADS_URL } from "config";
import { DeletePost } from "features/listings";
import { useDisclosure } from "hooks/useDisclosure";
import { useAuth } from "lib/auth";
import React from "react";
import { MapPin, Phone } from "react-feather";
import { Link, useNavigate } from "react-router-dom";

export const Card = ({
  image,
  title,
  pick_up_location,
  id,
  userId,
  slug,
  open,
}) => {
  const { user } = useAuth();

  return (
    <div className="bg-white shadow-md rounded-2xl overflow-hidden transform transition-transform duration-300 hover:scale-105">
      <Link
        to={open ? "#" : `/app/listings/${slug}`}
        onClick={open ? open : undefined}
      >
        <img
          src={
            image ? UPLOADS_URL + "/" + image : "https://placehold.co/600x400"
          }
          alt="post"
          className="w-full h-64 object-cover"
        />
      </Link>
      <div className="px-4 py-4">
        <Link
          to={open ? "#" : `/app/listings/${slug}`}
          onClick={open ? open : undefined}
          className="text-sm font-semibold text-gray-800 hover:text-orange-600"
        >
          {title}
        </Link>
        <div className="flex flex-row items-center justify-between space-x-2">
          <div className="flex items-center text-gray-600 text-xs my-2">
            <MapPin className="h-4 text-orange-500" />
            <span>{pick_up_location}</span>
          </div>
          {user._id === userId && (
            <div className="flex justify-between items-center">
              <div className="text-red-500 text-sm">
                {user._id === userId && (
                  <DeletePost
                    id={id}
                    triggerButton={
                      <button>
                        <TrashIcon className="h-4 w-4" />
                      </button>
                    }
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
