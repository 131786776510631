import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "assets/images/logo.svg";
import { useAuth } from "lib/auth";
import { Menu } from "react-feather";
import { useDisclosure } from "hooks/useDisclosure";
import { Button, Drawer } from "components/Elements";
import { TradesLink } from "./TradesLink";
import { NotificationsLink } from "./NotificationsLink";
import { BidsLink } from "./BidsLink";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { loginWithGoogle } from "features/auth";

export const Navbar = ({ page }) => {
  const { user, logout } = useAuth();
  const { close, open, isOpen } = useDisclosure();

  const [loginWithGoogleLoading, setLoginWithGoogleLoading] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    setLoginWithGoogleLoading(true);
    await loginWithGoogle();
    navigate("/app");
    setLoginWithGoogleLoading(false);
  };

  const copyReferralLink = () => {
    const referralLink = `https://dejunk.app/auth?referralCode=${user.referral.code}`;

    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: t("navbar.referral_copied"),
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        // Use SweetAlert2 to show an error message
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t("navbar.referral_failed"),
        });
      });
  };

  return (
    <>
      <div className="py-8 flex justify-between items-center">
        <Link
          to={`${user ? "/app" : "/"}`}
          className="flex items-center flex-shrink-0 text-white mr-6"
        >
          <img src={logo} alt="logo" className="h-6 md:h-12" />
        </Link>
        <div className="flex flex-row items-center space-x-8">
          {user && (
            <div className="flex flex-row items-center space-x-8">
              <BidsLink />
              <TradesLink />
              <NotificationsLink />
            </div>
          )}
          {user ? (
            <button
              onClick={() => {
                open();
              }}
            >
              <Menu className="h-6 w-6 md:h-8 md:w-8" />
            </button>
          ) : (
            <>
              <Button
                variant="outline"
                size="xs"
                onClick={() => navigate("/about")}
              >
                {t("sidebar.about")}
              </Button>
              <Button
                onClick={handleGoogleLogin}
                size="xs"
                isLoading={loginWithGoogleLoading}
                startIcon={
                  <svg
                    class="mr-2 -ml-1 w-4 h-4"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="google"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 488 512"
                  >
                    <path
                      fill="currentColor"
                      d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                    ></path>
                  </svg>
                }
              >
                {t("auth.continue_with_google")}
              </Button>
            </>
          )}
        </div>
      </div>
      <Drawer
        isOpen={isOpen}
        onClose={close}
        title=""
        size="sm"
        renderFooter={() => (
          <div className="text-center text-xs text-gray-400">
            <Link to="/terms-of-service" className="mx-1">
              {t("sidebar.terms_of_service")}
            </Link>
            <Link to="/privacy-policy" className="mx-1">
              {t("interface.link_policy")}
            </Link>
            <Link to="/cookie-policy" className="mx-1">
              {t("sidebar.cookie_policy")}
            </Link>
            <Link to="/ads-info" className="mx-1">
              {t("sidebar.ads_info")}
            </Link>
            <Link to="/about" className="mx-1">
              {t("sidebar.about")}
            </Link>
            <br />
            <span className="mx-1">{t("interface.footer_rights")}</span>
          </div>
        )}
      >
        {user ? (
          <>
            <div className="p-4 space-y-4">
              <div>
                <h2 className="text-xl font-semibold">{user.displayName}</h2>
                <p className="text-gray-500">{user.email}</p>
              </div>

              <ul className="grid grid-cols-1 gap-4">
                <li>
                  <Link
                    to="/app/listings"
                    onClick={close}
                    className="bg-white shadow-md rounded-lg block p-4 hover:bg-gray-200"
                  >
                    {t("sidebar.listings")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/app/settings/account"
                    onClick={close}
                    className="bg-white shadow-md rounded-lg block p-4 hover:bg-gray-200"
                  >
                    {t("sidebar.account_settings")}
                  </Link>
                </li>
                <li>
                  <a
                    href="#!"
                    onClick={() => logout()}
                    className="bg-red-100 text-red-500 shadow-md rounded-lg block p-4 hover:bg-gray-200"
                  >
                    <span className="text-md font-black">
                      {t("sidebar.sign_out")}
                    </span>
                    <br />
                    <span className="text-xs font-light text-gray-600">
                      {t("sidebar.sign_out_message")}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="p-4">
              <div
                id="alert-4"
                class="flex p-4 mb-4 text-yellow-800 rounded-lg bg-yellow-50 shadow mt-24"
                role="alert"
              >
                <div class="ml-3 text-xs font-medium">
                  <div className="text-center">
                    {t("sidebar.invite_message")}
                  </div>
                  <div className="my-1 text-center">
                    <div
                      onClick={copyReferralLink}
                      className="text-light text-orange-500 mb-3 cursor-pointer"
                    >
                      {`https://dejunk.app/auth?referralCode=${user.referral.code}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Button
              variant="outline"
              size="xs"
              onClick={() => navigate("/about")}
            >
              {t("sidebar.about")}
            </Button>
            <Button size="xs" onClick={() => navigate("/auth")}>
              {t("auth.login")}
            </Button>
          </>
        )}
      </Drawer>
    </>
  );
};
