import {
  Landing,
  CompleteRegistration,
  JoinWaitingList,
  ThankYou,
  About,
  TermsAndConditions,
  PrivacyPolicy,
} from "features/misc";
import { useAuth } from "lib/auth";
import { useRoutes } from "react-router-dom";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";

export const AppRoutes = () => {
  const auth = useAuth();

  const commonRoutes = [
    { path: "/", element: <Landing /> },
    { path: "/complete-registration", element: <CompleteRegistration /> },
    { path: "/join-waiting-list", element: <JoinWaitingList /> },
    { path: "/thank-you", element: <ThankYou /> },
    { path: "/about", element: <About /> },
    { path: "/terms-and-conditions", element: <TermsAndConditions /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
  ];

  const routes = auth.user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
