import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getPost = ({ slug }) => {
  return axios.get(`/posts/slug/${slug}`);
};

export const usePost = ({ slug, config }) => {
  return useQuery({
    ...config,
    queryKey: ['post', slug],
    queryFn: () => getPost({ slug }),
  });
};
