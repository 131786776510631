import clsx from 'clsx';

import { FieldWrapper } from './FieldWrapper';

export const TextAreaField = (props) => {
  const {
    rows = 3,
    label,
    className,
    disabled,
    value,
    registration,
    caption,
    placeholder,
    onChange,
    error,
    maxLength,
  } = props;

  return (
    <FieldWrapper label={label} error={error}>
      <textarea
        id="message"
        rows={rows}
        className={clsx(
          'block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500',
          className
        )}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        value={value}
        {...registration}
        onChange={onChange}
      ></textarea>
      <div className="my-1 text-xs flex justify-end">{caption}</div>
    </FieldWrapper>
  );
};
