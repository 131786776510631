import { Button, ConfirmationDialog } from "components/Elements";
import { TrashIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";

import { useDeletePost } from "../api/deletePost";

export const DeletePost = ({ id, triggerButton }) => {
  const deletePostMutation = useDeletePost();

  return (
    <>
      <ConfirmationDialog
        icon="danger"
        title="Remove Post"
        body="Are you sure you want to remove this post?"
        triggerButton={triggerButton}
        confirmButton={
          <Button
            isLoading={deletePostMutation.isLoading}
            type="button"
            className="bg-red-600"
            onClick={async () =>
              await deletePostMutation.mutateAsync({ id: id })
            }
          >
            Remove
          </Button>
        }
      />
    </>
  );
};

DeletePost.propTypes = {
  id: PropTypes.string.isRequired,
};
