import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotificationStore } from "stores/notifications";
import { useMutation } from "react-query";

export const createTransaction = ({ data }) => {
  return axios.post(`/transactions`, data);
};

export const useCreateTransaction = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newTransaction) => {
      await queryClient.cancelQueries("tranasctions");
      const previousTransactions = queryClient.getQueryData("tranasctions");

      queryClient.setQueryData("tranasctions", [
        ...(previousTransactions || []),
        newTransaction.data,
      ]);

      return { previousTransactions };
    },
    onError: (_, __, context) => {
      if (context?.previousTransactions) {
        queryClient.setQueryData("tranasctions", context.previousTransactions);
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("tranasctions");
      addNotification({
        type: "success",
        title: data.msg,
      });
    },
    ...config,
    mutationFn: createTransaction,
  });
};
