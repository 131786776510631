import React, { useEffect } from "react";
import { Button, Spinner } from "components/Elements";
import { useReadNotification } from "../api/readNotification";
import { useUnReadNotification } from "../api/unReadNotifications";
import { Link, useNavigate } from "react-router-dom";
import { useBid } from "features/bids";
// import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import DejunkLogo from "assets/images/dejunk-logo.svg";
import { FLW_PUBLIC_KEY } from "config";
import { useAuth } from "lib/auth";
import { generateRefNo } from "utils/generateRefNo";
import { useCreateTransaction } from "features/transactions";
import { useState } from "react";
import { BID_STATUS } from "config";
import { useSoldPost } from "../api/getSoldPost";
import { usePost } from "features/dejunk";

export const Notification = ({
  id,
  title,
  clicked,
  datetimeCreated,
  route,
  bid,
  message,
  post,
}) => {
  const isClicked = clicked;

  // const [amount, setAmount ] = useState(1.0);

  const readNotificationMutation = useReadNotification();
  const unReadNotificationMutation = useUnReadNotification();

  const navigate = useNavigate();

  const { user } = useAuth();

  // const transactionMutation = useCreateTransaction();

  // const tx_ref = generateRefNo();
  // const config = {
  //   public_key: FLW_PUBLIC_KEY,
  //   tx_ref: tx_ref,
  //   amount: amount,
  //   currency: "USD",
  //   payment_options: "card,mobilemoney,ussd",
  //   order_id: `ORD-${bid}`,
  //   order_desc: `Dejunk Broker Fee Payment"`,
  //   redirect_url: `http://localhost:3000/app/bids/${bid}`,
  //   customer: {
  //     email: user.email,
  //     phonenumber: user.phone,
  //     name: user.displayName,
  //   },
  //   customizations: {
  //     title: "Dejunk Broker Fee Payment",
  //     description: `Payment for "${title}"`,
  //     logo: DejunkLogo,
  //   },
  // };
  // const handleFlutterPayment = useFlutterwave(config);

  return (
    <div
      className={` p-5 mb-3 rounded-2xl shadow-sm ${
        !isClicked ? "border bg-white" : "bg-gray-200"
      }`}
    >
      <div className="flex justify-between items-center mb-1">
        <div
          className={`text-sm font-semibold ${
            isClicked ? "text-gray-800" : "text-red-500"
          } mr-2`}
        >
          {title}
        </div>
        <div className="mt-2 flex flex-row items-center space-x-2">
          {!isClicked && (
            <Button
              size="xs"
              variant="outline"
              onClick={async () => {
                await readNotificationMutation.mutateAsync({
                  notification_id: id,
                });
              }}
              isLoading={readNotificationMutation.isLoading}
            >
              Read
            </Button>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <Link
            to={
              route === "bids" ? `/app/${route}/${bid?._id}` : `/app/${route}`
            }
            className={`text-xs font-semibold ${
              isClicked ? "text-gray-800" : "text-flamingo-500"
            }`}
          >
            {message}
          </Link>
        </div>
        <div className="text-xs text-gray-600">
          {/* <span className="font-semibold">
            {info}
          </span> */}
          {/* <span className="ml-1">{portfolio}</span> */}
        </div>
      </div>
      <div className="text-xs text-gray-500">{datetimeCreated}</div>
    </div>
  );
};
