import { Button } from "components/Elements";
import { Form, FormDrawer, InputField } from "components/Form";
import PhoneInput from "react-phone-number-input";
import { useAuth } from "lib/auth";
import { useNavigate } from "react-router-dom";
import { Phone, X } from "react-feather";
import { useAddPhoneNumber } from "../api/addPhoneNumber";
import { useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";

export const AddPhoneNumber = () => {
  const addPhoneNumberMutation = useAddPhoneNumber();
  const [phone_number, setPhone_number] = useState("");

  const { user } = useAuth();

  const { t } = useTranslation()

  const navigate = useNavigate();

  return (
    <>
      <FormDrawer
        isDone={addPhoneNumberMutation.isSuccess}
        size="md"
        triggerButton={
          <div
            type="button"
            className="bg-gradient-to-r from-orange-500 to-red-600 hover:from-red-600 hover:to-orange-500 animate-pulse hover:cursor-pointer rounded-lg p-8 md:p-12 flex flex-row items-center justify-start space-x-4"
          >
            <Phone className="text-white" size={32} />
            <div className="flex flex-col items-start justify-start text-white">
              <div className="text-2xl font-bold">Add your mobile number now!</div>
              <div className="text-sm">
                Your number will appear on each post you make.
              </div>
            </div>
          </div>
        }
        title="Add Phone number"
        submitButton={
          <Button
            form="add-phone-number"
            type="submit"
            size="sm"
            isLoading={addPhoneNumberMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="add-phone-number"
          onSubmit={async (values) => {
            Object.assign(values, { phone: phone_number });
            await addPhoneNumberMutation.mutateAsync({ data: values });
            navigate("/app");
          }}
        >
          {({ register, formState }) => (
            <>
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="UG"
                onChange={(e) => {
                  if (e === null || e === undefined) {
                    setPhone_number("");
                  } else setPhone_number(e);
                }}
                className="border-0 mt-4"
              />
              {phone_number && !isValidPhoneNumber(phone_number) && (
                <span className="text-sm font-semibold text-red-500 mt-2">
                  Enter a valid phone number
                </span>
              )}
            </>
          )}
        </Form>
      </FormDrawer>
    </>
  );
};
