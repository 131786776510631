import { Spinner } from "components/Elements";
import { lazyImport } from "utils/lazyImport";
import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { MainLayout } from "components/Layouts";
import { Account, Profile } from "features/users";

const { ListingsRoutes } = lazyImport(
  () => import("features/listings"),
  "ListingsRoutes"
);
const { DejunksRoutes } = lazyImport(
  () => import("features/dejunk"),
  "DejunksRoutes"
);
const { WishlistRoutes } = lazyImport(
  () => import("features/wishlist"),
  "WishlistRoutes"
);
const { NotificationsRoutes } = lazyImport(
  () => import("features/notifications"),
  "NotificationsRoutes"
);
const { TradesRoutes } = lazyImport(
  () => import("features/trades"),
  "TradesRoutes"
);
const { SettingsRoutes } = lazyImport(
  () => import("features/settings"),
  "SettingsRoutes"
);
const { BidsRoutes } = lazyImport(
  () => import("features/bids"),
  "BidsRoutes"
);
const { BookedRoutes } = lazyImport(
  () => import("features/booked"),
  "BookedRoutes"
);
const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: "/app",
    element: <App />,
    children: [
      { path: "/app/listings/*", element: <ListingsRoutes /> },
      { path: "/app/wishlist/*", element: <WishlistRoutes /> },
      { path: "/app/notifications/*", element: <NotificationsRoutes /> },
      { path: "/app/trades/*", element: <TradesRoutes /> },
      { path: "/app/settings/*", element: <SettingsRoutes /> },
      { path: "/app/bids/*", element: <BidsRoutes /> },
      { path: "/app/booked/*", element: <BookedRoutes /> },
      { path: "/app/profile", element: <Profile /> },
      { path: "/app/account", element: <Account /> },
      { path: "/app", element: <DejunksRoutes /> },
      { path: "*", element: <Navigate to="." /> },
    ],
  },
];
