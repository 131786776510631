import { useAuth } from "lib/auth";
import React from "react";
import md5 from "md5";
import { Link } from "react-router-dom";

export const Account = () => {
  const { user, logout } = useAuth();

  const md5Hash = md5(user.email);

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="bg-white shadow-md p-4">
        <div className="flex items-center space-x-4">
          <img
            src={`https://www.gravatar.com/avatar/${md5Hash}`}
            alt="User Avatar"
            className="w-12 h-12 rounded-full"
          />
          <div>
            <h2 className="text-xl font-semibold">{user.name}</h2>
            <p className="text-gray-500">{user.email}</p>
          </div>
        </div>
      </div>

      <div className="p-4 space-y-4">
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <li>
            <Link
              to="/app/listings"
              className="bg-white shadow-md rounded-lg block p-4 hover:bg-gray-200"
            >
              My Listings
            </Link>
          </li>
          <li>
            <Link
              to="/app/activity"
              className="bg-white shadow-md rounded-lg block p-4 hover:bg-gray-200"
            >
              Your Activity
            </Link>
          </li>
          <li>
            <Link
              to="/app/settings"
              className="bg-white shadow-md rounded-lg block p-4 hover:bg-gray-200"
            >
              Settings
            </Link>
          </li>
          <li>
            <Link
              to="/app/password-security"
              className="bg-white shadow-md rounded-lg block p-4 hover:bg-gray-200"
            >
              Password and Security
            </Link>
          </li>
          <li>
            <Link
              to="/app/payments"
              className="bg-white shadow-md rounded-lg block p-4 hover:bg-gray-200"
            >
              Payments
            </Link>
          </li>
          <li>
            <a
              href="#!"
              onClick={() => logout()}
              className="bg-white shadow-md rounded-lg block p-4 hover:bg-gray-200"
            >
              Log Out
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
