import { Button } from "components/Elements";
import { Form, InputField } from "components/Form";
import { useAuth } from "lib/auth";
import PropTypes from "prop-types";
import * as z from "zod";
import { useCookies } from "react-cookie";

const schema = z.object({
  otp: z.string().min(1, "Required"),
});

export const VerifyOTPForm = ({ onSuccess }) => {
  const { login, isLoggingIn } = useAuth();
  const [cookies, setCookie] = useCookies(["phoneNumber"]);

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          const { otp } = values;
          console.log(cookies.phoneNumber);
          await login({
            phone: cookies.phoneNumber,
            otp,
          });
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              error={formState.errors["otp"]}
              registration={register("otp")}
            />
            <div>
              <Button isLoading={isLoggingIn} type="submit" className="w-full">
                Continue
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

VerifyOTPForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
