export const API_URL =
  process.env.REACT_APP_API_URL || "http://localhost:5002/api";
export const UPLOADS_URL =
  process.env.REACT_APP_UPLOADS_URL || "http://localhost:5002";
export const JWT_SECRET = "123456";
export const FLW_PUBLIC_KEY = process.env.REACT_APP_FLW_PUBLIC_KEY;
export const BID_STATUS = {
  ACCEPTED: "ACCEPTED",
  SUBMITTED: "SUBMITTED",
  DECLINED: "DECLINED",
  CLEARED: "CLEARED",
};

export const LANGUAGES = {
  EN: "en",
  FR: "fr",
  SW: "sw",
};
