import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getBuyerBids = () => {
  return axios.get('/bids/buyer');
};

export const useBuyerBids = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['buyerbids'],
    queryFn: () => getBuyerBids(),
  });
};
