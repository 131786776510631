import clsx from "clsx";

import { FieldWrapper } from "./FieldWrapper";

export const PhoneInputField = (props) => {
  const inputHandler = (e) => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  const { className, caption, label, registration, error } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <div
        className={clsx(
          "flex flex-row items-center space-x-2 appearance-none block w-full px-8 py-4  bg-orange-100 rounded-full shadow-sm  sm:text-lg",
          className
        )}
      >
        <div
          className="text-black"
          style={{
            letterSpacing: "0.65em",
          }}
        >
          +256
        </div>
        <input
          type="number"
          maxLength={9}
          onKeyPress={inputHandler}
          className="block w-full bg-orange-100 border-0 placeholder-gray-400 focus:outline-none focus:ring-0"
          style={{
            letterSpacing: "0.65em",
          }}
          {...registration}
        />
      </div>
      <div className="my-1 text-xs flex justify-end">{caption}</div>
    </FieldWrapper>
  );
};
