import { Route, Navigate, Routes } from "react-router-dom";
import { GetOTP } from "./GetOTP";

import { VerifyOTP } from "./VerifyOTP";
import { Register } from "./Register";
import { Login } from "./Login";
import { ResetPassword } from "./ResetPassword";
import { ForgotPassword } from "./ForgotPassword";
import { ForgotPasswordCheckEmail } from "./ForgotPasswordCheckEmail";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:token" element={<ResetPassword />} />
      <Route
        path="forgot-password/check-email"
        element={<ForgotPasswordCheckEmail />}
      />
      <Route path="get-otp" element={<GetOTP />} />
      <Route path="verify-otp" element={<VerifyOTP />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
