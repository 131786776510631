import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getMyPosts = () => {
  return axios.get('/posts/mine');
};

export const useMyPosts = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['myListings'],
    queryFn: () => getMyPosts(),
  });
};
