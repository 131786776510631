import { MainLayout } from "components/Layouts";
import { Layout } from "../components/Layout";

export const ForgotPasswordCheckEmail = () => {
  return (
    <MainLayout title="Check your email and follow instructions to complete password reset.">
      <Layout>
        <div className="flex flex-col items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-md text-center">
            <h1 className="text-3xl font-semibold text-orange-500 mb-4">
              Password Reset Instructions
            </h1>
            <p className="text-gray-600 mb-4">
              We've sent you an email with instructions to reset your password.
            </p>
            <div className="py-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-16 h-16 mx-auto text-orange-500"
              >
                <circle cx="12" cy="12" r="10" />
                <line x1="16" y1="12" x2="12" y2="8" />
                <line x1="8" y1="12" x2="12" y2="16" />
              </svg>
            </div>
            <p className="text-gray-600">
              Please check your email and follow the instructions provided to
              complete the password reset process. If you don't receive an
              email, please check your spam folder.
            </p>
          </div>
        </div>
      </Layout>
    </MainLayout>
  );
};
