import React from "react";
import { TeamMate } from "./TeamMate";
import JonahImg from "assets/images/team/jonathan.png";
import lonnahImg from "assets/images/team/lonnah.png";
import hakeemImg from "assets/images/team/hakeem.png";
import isabellaImg from "assets/images/team/isabella.png";
import { useTranslation } from "react-i18next";

export const Team = () => {
  const { t } = useTranslation();

  const teammates = [
    {
      id: 1,
      src: `${JonahImg}`,
      name: "Jonathan Mwebaze",
      title: t('landing.code_sorcerer'),
      twitter: "jonahgeek",
      mail: "jonahgeek@gmail.com",
      description:
        t('landing.code_sorcerer_description'),
    },
    {
      id: 2,
      src: `${lonnahImg}`,
      name: "Lonnah Alinda",
      title: t('landing.design_maestro'),
      twitter: "lonnah2000",
      mail: "tumwesigyelonnah@gmail.com",
      description:
        t('landing.design_maestro_description'),
    },
    {
      id: 3,
      src: `${hakeemImg}`,
      name: "Hakeem Kavuma",
      title: t('landing.marketing_marven'),
      twitter: "hakeemcounsel",
      mail: "kavumahakeem23@gmail.com",
      description:
        t('landing.marketing_marven_description'),
    },
    {
      id: 4,
      src: `${isabellaImg}`,
      name: "Isabella Ariho",
      title: t('landing.legal_luminary'),
      twitter: "aruho15",
      mail: "isabella.ariho@gmail.com",
      description:
        t('landing.legal_luminary_description'),
    },
  ];
  return (
    <div>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-4 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h1 class="text-4xl font-medium title-font mb-4 text-gray-900 tracking-widest pacifico">
              {t('landing.meet_the_team')}
            </h1>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
              {t('landing.team_description')}
            </p>
          </div>
          <div class="flex flex-wrap -m-4">
            {teammates.map((item, index) => (
              <TeamMate key={index} {...item} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};
