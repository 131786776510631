import React from "react";

export const Header = ({ label }) => {
  return (
    <div className="mb-4 p-4 rounded-md">
      <div className="flex flex-row items-center space-x-2">
        <h1 className="text-xl font-bold">{label}</h1>
      </div>
    </div>
  );
};
