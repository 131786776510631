import React, { useState } from "react";
import { useBuyerBids } from "../api/getBuyerBids";
import { DeclineBid } from "./DeclineBid";
import { AcceptBid } from "./AcceptBid";
import { usePreferences } from "features/preferences";
import { BID_STATUS } from "config";
import { Button, Spinner } from "components/Elements";
import { X } from "react-feather";

export const AcceptedBidsList = () => {
  const acceptedBidsQuery = useBuyerBids();

  const preferencesQuery = usePreferences();

  const [sellerDetails, setSellerDetails] = useState();

  if (acceptedBidsQuery.isLoading || preferencesQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  }

  if (!acceptedBidsQuery.data || !preferencesQuery.data) return null;

  const acceptedBids = acceptedBidsQuery.data?.filter(
    (bid) => bid.status === BID_STATUS.CLEARED
  );

  return (
    <>
      {acceptedBids.length ? (
        <div className="px-5 text-md font-bold mb-3 text-gray-600 bg-white rounded-2xl shadow p-3">
          Cleared
        </div>
      ) : null}
      <div className="grid grid-cols-1 gap-4">
        {acceptedBids.map((bid, index) => (
          <div key={index} className="bg-white rounded-2xl shadow mb-3 p-8">
            {sellerDetails ? (
              <>
                <div className="flex flex-row items-center justify-between">
                  <div className="">
                    <span className="text-gray-400 text-xs">Post</span> <br />
                    <span className="text-gray-800 text-sm font-semibold">
                      {bid.post.title}
                    </span>
                  </div>
                  <button onClick={() => setSellerDetails(false)} className="flex flex-row items-center space-x-2">
                    <X className="h-4 w-4" /> <span>Close</span>
                  </button>
                </div>
                <div className="">
                  <span className="text-gray-400 text-xs">Seller Contact</span>{" "}
                  <br />
                  <span className="text-red-500 text-xs font-semibold">
                    {bid.post.contact}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-row items-center justify-between">
                  <div className="">
                    <span className="text-gray-400 text-xs">Post</span> <br />
                    <span className="text-gray-800 text-sm font-semibold">
                      {bid.post.title}
                    </span>
                  </div>
                  <div className="">
                    <span className="text-gray-400 text-xs">PR ID</span> <br />
                    <span className="text-red-500 text-xs font-semibold">
                      {bid._id}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between">
                  <div className="">
                    <span className="text-gray-400 text-xs">Amount</span> <br />
                    <span className="text-gray-800 text-md font-bold">
                      {preferencesQuery.data.currency} {bid.amount}
                    </span>
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <Button
                      size="xs"
                      variant="outline"
                      onClick={() => setSellerDetails(true)}
                    >
                      View Seller Details
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </>
  );
};
