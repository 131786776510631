import { MainLayout } from "components/Layouts";
import React from "react";

export const PrivacyPolicy = () => {
  window.scrollTo(0, 0);

  return (
    <MainLayout>
      <div className="flex flex-col mt-12 bg-white rounded-2xl shadow p-4 md:p-12 text-sm">
        <div className="text-2xl mb-3">PRIVACY POLICY</div>
        <div class="">
          <p class="mb-4">Effective Date: 1st January 2024</p>

          <p class="mb-4">
            Welcome to Dejunk! This Privacy Policy outlines how your personal
            information is collected, used, and shared when you use the Dejunk
            application ("App") and the services provided by Dejunk
            ("Services"). By using the App and Services, you agree to the terms
            of this Privacy Policy.
          </p>

          <h2 class="text-lg font-semibold mb-4">Information We Collect</h2>
          <p class="mb-4">
            To use the App and Services, you must create an account. We collect
            information you provide, including but not limited to your name,
            email address, and other relevant details.
          </p>

          <h2 class="text-lg font-semibold mb-4">
            How We Use Your Information
          </h2>
          <p class="mb-4">
            We use the information collected to provide and improve our
            Services, including processing transactions, managing your account,
            and communicating with you.
          </p>

          <h2 class="text-lg font-semibold mb-4">Sharing Your Information</h2>
          <p class="mb-4">
            We may share your personal information to comply with applicable
            laws and regulations or to protect our rights. Your information may
            also be shared with third-party service providers for the purpose of
            providing the Services.
          </p>

          <h2 class="text-lg font-semibold mb-4">Security</h2>
          <p class="mb-4">
            We take reasonable measures to protect your personal information.
            However, no method of transmission over the internet or electronic
            storage is entirely secure, and we cannot guarantee absolute
            security.
          </p>

          <h2 class="text-lg font-semibold mb-4">
            Changes to This Privacy Policy
          </h2>
          <p class="mb-4">
            We may update this Privacy Policy from time to time, and any changes
            will be effective immediately. It is your responsibility to review
            this Privacy Policy periodically for changes.
          </p>

          <h2 class="text-lg font-semibold mb-4">Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at{" "}
            <a href="mailto:support@dejunk.app" class="text-red-500">
              support@dejunk.app
            </a>
            .
          </p>
        </div>
      </div>
    </MainLayout>
  );
};
