import { Navigate, Route, Routes } from 'react-router-dom';
import { Dejunk } from './Dejunk';


export const DejunksRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Dejunk />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
