import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getPreferences = () => {
  return axios.get("/preferences/mine");
};

export const usePreferences = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["preferences"],
    queryFn: () => getPreferences(),
  });
};
